import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-password-sent',
  templateUrl: './password-sent.component.html',
  styleUrls: ['./password-sent.component.scss']
})
export class PasswordSentComponent implements OnInit {

  private queryParamsSub: Subscription;
  private src: string;

  constructor(private router: Router,
    private route: ActivatedRoute) { }

  ngOnInit(): void {
    this.queryParamsSub = this.route.queryParamMap.subscribe(params => {
      this.src = params.get('src');
    });
  }

  ngOnDestroy() {
    this.queryParamsSub.unsubscribe();
  }

  goToLoginPage() {
    if (this.src === 'quotation') {
      this.router.navigate(['/login-configuration']);
    } else {
      this.router.navigate(['/login']);
    }
  }
}
