<div class="login-borraccia">
    <div class="section">
        <div style="text-align: center;margin-top: 7px;margin-bottom: 170px;">
            <span class="image-preview" style="background-size: cover;"
                [ngStyle]="{'background-image': 'url(' + bottleURL + ')'}">
            </span>
        </div>
        <div class="container" style="padding-top: 100px;">
            <p style=" border-top: 1px solid #CECECE; margin-top: 2px;margin-bottom: 25px;"></p>
            <p class="boxSummary">
                <span>{{ 'color_bottle' | translate }}</span>
                <span class="color">
                    <span class="color-dot"
                        [ngStyle]="{'background-color': configurationModel.water_bottle_color_hex}"></span>
                    <span class="color-text">{{colorBottleName}}</span>
                </span>
            </p>
        </div>
        <div class="container ">
            <p class="boxSummary">
                <span>{{ capName }}</span>
                <span class="color">
                    <span class="color-dot" [ngStyle]="{'background-color': configurationModel.cap_color_hex}"></span>
                    <span class="color-text">{{colorCapName}}</span>
                </span>
            </p>
        </div>
        <div class="container ">
            <p class="boxSummary">
                <span>{{ valveName }}</span>
                <span class="color">
                    <span class="color-dot" [ngStyle]="{'background-color': configurationModel.valve_color_hex}"></span>
                    <span class="color-text">{{colorValveName}}</span>
                </span>
            </p>
        </div>
        <div class="container ">
            <p class="boxSummary">
                <span>{{ 'capacity_bottle' | translate }}</span>
                <span class="color">
                    <span class="color-text">{{configurationModel.water_bottle_capacity}}ml</span>
                </span>
            </p>
        </div>
        <div class="container ">
            <p class="boxSummary">
                <span>{{ 'quantity_order' | translate }}</span>
                <span class="color">
                    <span class="color-text">{{configurationModel.quantity}}pz</span>
                </span>
            </p>
        </div>
        <div *ngIf="configurationModel.price" class="container ">
            <p class="boxSummary">
                <span>{{ 'order_price' | translate }}</span>
                <span class="color">
                    <span class="color-text">{{ getOrderPrice() }}€</span>
                </span>
            </p>
        </div>
    </div>
    <div class="login-borraccia-top">

    </div>

</div>

<div class="section">
    <form #f="ngForm" (ngSubmit)="sendRequest()">
        <div class="container is-fluid register">
            <p>{{ configurationModel.price ? ('order_request_title' | translate) : ('quotation_request_title' | translate) }} <img src="/assets/img/red_dot.png"></p>
        </div>
        <div class="container is-fluid register-subtitle">
            <p>{{ 'quotation_request_subtitle_first' | translate }} {{firstName}}!</p>
        </div>
        <div class="container is-fluid register-subtitle">
            <p>{{ configurationModel.price ? ('order_request_subtitle_second' | translate) : ('quotation_request_subtitle_second' | translate) }}</p>
        </div>
        <div class="container is-fluid register-form">

            <div class="columns is-gapless">
                <div class="column is-6">
                    <div class="columns is-multiline">
                        <div class="column is-half">
                            <div class="field" *ngIf="!firstName">
                                <label class="label">{{ 'request_form_name' | translate }}</label>
                                <div class="control">
                                    <input class="input" type="text" placeholder="{{'form_name' | translate}}"
                                        [(ngModel)]="quotationModel.name" required name="name">
                                </div>
                            </div>
                        </div>
                        <div class="column is-half">
                            <div class="field" *ngIf="!lastName">
                                <label class="label">{{ 'request_form_surname' | translate }}</label>
                                <div class="control">
                                    <input class="input" type="text" placeholder="{{'form_surname' | translate}}"
                                        [(ngModel)]="quotationModel.surname" required name="surname">
                                </div>
                            </div>
                        </div>
                        <div class="column is-half">
                            <div class="field">
                                <label class="label ">{{ 'quotation_form_telephone' | translate }}</label>
                                <div class="control">
                                    <input class="input" type="text" placeholder="{{'form_telephone' | translate}}"
                                        [(ngModel)]="quotationModel.phone_number" required name="telephone">
                                </div>
                            </div>
                        </div>
                        <div class="column is-half">
                            <div class="field">
                                <label class="label email">Email</label>
                                <div class="control">
                                    <input name="userEmail" email class="input" type="email" placeholder="{{'form_email' | translate}}"
                                        [(ngModel)]="quotationModel.email"  #userEmail="ngModel" required >
                                </div>
                                <div *ngIf="userEmail.errors?.email" class="field">
                                    <div class="error">{{ 'error_format_email' | translate }}</div>
                                </div>
                            </div>

                        </div>
                        <div class="column is-half">
                            <div class="field">
                                <label class="label ">{{ 'quotation_form_state' | translate }}</label>
                                <div class="control">
                                    <input class="input" type="text" [(ngModel)]="quotationModel.residence_state" required
                                        name="state">
                                </div>
                            </div>
                        </div>
                        <div class="column is-half mobile-hidden">
                        </div>
                        <div class="column is-half">
                            <div class="field messagge">
                                <label class="label">{{ 'quotation_form_messagge' | translate }}</label>
                                <div class="control">
                                    <textarea class="textarea " type="text" rows="5" style="background-color: #F0F3F3;"
                                        [(ngModel)]="quotationModel.messagge" required name="messagge"></textarea>
                                </div>
                            </div>
                        </div>
                        <div class="column is-half">
                            <div class="field messagge">
                                <label class="label">{{ 'quotation_form_notes' | translate }}</label>
                                <div class="control">
                                    <textarea class="textarea " type="text" rows="5" style="background-color: #F0F3F3;"
                                        [(ngModel)]="quotationModel.notes" name="notes"></textarea>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="column is-6">
            <div class="field">
                <div class="control submit ">
                    <button type="submit" style="background-color: #E2001A;" [disabled]="f.form.invalid"
                        class="button is-primary is-fullwidth">{{'send_request' | translate}}</button>
                </div>
            </div>
        </div>

    </form>
</div>