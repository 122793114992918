import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { UserService } from 'src/app/services/user.service';
import { RegisterRequest } from 'src/app/models/registerRequest';
import { UserDataService } from 'src/app/services/user-data.service';
import { EventService } from 'src/app/services/event.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.scss']
})
export class RegisterComponent implements OnInit {

  clickFirst = false;
  clickSecond = false;
  clickThird = false;
  email?: string;
  password?: string;
  firstName?: string;
  lastName?: string;
  showPassword = false;

  constructor(private router: Router,
    private userService: UserService,
    private userDataService: UserDataService,
    private eventService: EventService,
    private translate: TranslateService) { }

  ngOnInit(): void {
  }

  clickCheckbox1() {
    if (this.clickFirst === false) {
      this.clickFirst = true;
    } else {
      this.clickFirst = false;
    }
  }

  clickCheckbox2() {
    if (this.clickSecond === false) {
      this.clickSecond = true;
    } else {
      this.clickSecond = false;
    }
  }

  clickCheckbox3() {
    if (this.clickThird === false) {
      this.clickThird = true;
    } else {
      this.clickThird = false;
    }
  }

  createAccount() {
    this.eventService.showSpinner();
    const userRegister: RegisterRequest = {
      firstName: this.firstName,
      lastName: this.lastName,
      email: this.email,
      password: this.password,
      news: this.clickThird
    };
    this.userService.registerUser(userRegister).then(response => {
      console.log(response);
      localStorage.setItem('token', response.accessToken);
      this.userDataService.checkUser().then(user => {
        console.log(user);
        if (user !== null) {
          if (this.userDataService.configuratorStatus) {
            this.router.navigate(['/configurator'], { queryParams: { id: this.userDataService.configuratorStatus.id } });
          } else {
            this.router.navigate(['/home']);
          }
        } else {
          this.eventService.hideSpinner();
        }
      }).catch(error => {
        this.eventService.hideSpinner();
        console.log(error);
        this.eventService.setTextPopupText(this.translate.instant('error_create_user'));
        this.eventService.showTextPopup();
        return null;
      });
    }).catch(error => {
      this.eventService.hideSpinner();
      console.log(error);
      this.eventService.setTextPopupText(this.translate.instant('error_create_user'));
      this.eventService.showTextPopup();
      return null;
    });
  }

  goToLoginPage() {
    this.router.navigate(['/login']);
  }

  showHidePassword() {
    this.showPassword = !this.showPassword;
  }
}
