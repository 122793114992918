
<app-share-modal *ngIf='showModal' [url]='urlToShare' [configurationId]='configurationToShare' (onClose)="onCloseDialog()"></app-share-modal>

<div class="page" id="page">

<div class="section">
    <div class="columns is-centered tablet">
        <div class="column is-2" [ngClass]="{'inactive': step !== 0}">
            <span class="textButton1">1. <span class="textButton2">{{ 'first_step' | translate }}</span></span>
        </div>
        <div class="column is-2" [ngClass]="{'inactive': step !== 1}">
            <span class="textButton1">2. <span class="textButton2">{{ 'second_step' | translate }}</span></span>
        </div>
        <div class="column is-2" [ngClass]="{'inactive': step !== 2}">
            <span class="textButton1">3. <span class="textButton2">{{ 'third_step' | translate }}</span></span>
        </div>
    </div>
    <div class="mobile-step" *ngIf="step === 0">
        1. {{ 'first_step' | translate }}
    </div>
    <div class="mobile-step" *ngIf="step === 1">
        2. {{ 'second_step' | translate }}
    </div>
    <div class="mobile-step" *ngIf="step === 2">
        3. {{ 'third_step' | translate }}
    </div>
</div>


<div class="columns">
    <div class="column is-two-fifths-tablet bottle">

        <span class="bottle-title">{{ name }}
            <img style="margin-left: 7px;" src="/assets/img/dot.png">
            <div style="margin-left: 8px;" class="bottle-title-2 tablet" ellipsis [innerHTML]="description" (ellipsis-click-more)="moreClicked($event)"></div>
        </span>

        <div class="engine-container" #engineContainer>
            <div class="bottom-image-oval"></div>

            <app-engine class="engine-box" #appEngine></app-engine>

            <div class="bottom-image-text">
                {{ 'drag_water_bottle' | translate }}
            </div>

            <div class="bottom-image-actions">
                <!-- <img src="/assets/img/icon_rotation.png"> -->
            </div>
        </div>

    </div>

    <div class="column">
        <app-configurator-page #page [step]="step" [cap]="cap" [bottle]="waterBottle" [variants]="variants" [selectedVariant]="selectedVariant"
            (quantity)="onQuantityChanged($event)"
            (imagesBase64)="onImagesChanged($event)"
            (bottleColorChanged)="onBottleColorChanged($event)"
            (capColorChanged)="onCapColorChanged($event)"
            (valveColorChanged)="onValveColorChanged($event)"
            (textureChanged)="onTextureChanged($event)"
            (variantChanged)="variantChanged($event)"></app-configurator-page>
    </div>
</div>

<div class="bottom-bar tablet">

    <button class="buttonSummary" (click)="save()">
        <span class="next-step" *ngIf="step !== 2">{{ 'next_step' | translate }}</span>
        <span class="next-step" *ngIf="step === 2">{{ quantity.price ? ('send_request_caps' | translate) : ('quotation_request' | translate) }}</span>
        <img class="arrow"  src="/assets/img/icon__arrow_right_in_circle.png" >
    </button>

    <button class="button-back" *ngIf="step !== 0" (click)="back()">
        <img class="arrow"  src="/assets/img/icon__arrow_left_in_circle.png" >
        <span class="next-step" >{{ ('back' | translate ).toUpperCase() }}</span>
    </button>

</div>

<div class="bottom-bar-mobile">
    <button class="buttonSummary" (click)="save()">
        <span class="next-step" *ngIf="step !== 2">{{ 'next_step_mobile' | translate }}</span>
        <span class="next-step" *ngIf="step === 2">{{ 'next_step_mobile' | translate }}</span>
        <img class="arrow"  src="/assets/img/icon__arrow_right_in_circle.png" >
    </button>

    <button class="button-back" *ngIf="step !== 0" (click)="back()">
        <img class="arrow"  src="/assets/img/icon__arrow_left_in_circle.png" >
        <span class="next-step" >{{ ('back' | translate ).toUpperCase() }}</span>
    </button>
</div>


</div>
