import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { Page } from '../models/page';
import { WaterBottle } from '../models/waterBottle';
import { Cap } from '../models/cap';

@Injectable()
export class MainService {

  constructor(private http: HttpClient) { }

  getWaterBottles(variant?: string): Promise<Page<WaterBottle>> {
    let path = '/water_bottles';
    if (variant) {
      path = path + '?variant=' + variant;
    }
    return this.http.get<Page<WaterBottle>>(environment.apiName + path,
      { headers: this.getRequestHeaders() }).toPromise();
  }

  getWaterBottle(id: number): Promise<WaterBottle> {
    return this.http.get<WaterBottle>(environment.apiName + '/water_bottles/' + id,
      { headers: this.getRequestHeaders() }).toPromise();
  }

  getCap(id: number): Promise<Cap> {
    return this.http.get<Cap>(environment.apiName + '/caps/' + id,
      { headers: this.getRequestHeaders() }).toPromise();
  }

  getRequestHeaders() {
    const headers = new HttpHeaders(
        {
          'Content-Type': 'application/json',
          Accept: 'application/json'
        }
    );
    if (localStorage.getItem('token')) {
      headers.append('Authorization', 'Bearer ' + localStorage.getItem('token'));
    }
    return headers;
  }

}
