import { Injectable } from '@angular/core';
import { UserService } from './user.service';
import { User } from '../models/user';
import { ConfiguratorStatus } from '../pages/configurator/configurator-page/configurator-page.component';
import { EventService } from './event.service';

@Injectable()
export class UserDataService {

  constructor(private userService: UserService, private eventService: EventService) { }

  private user?: User;

  configuratorStatus?: ConfiguratorStatus;

  public checkUser(): Promise<User> {
    const token = localStorage.getItem('token');
    if (token == null) {
      return Promise.resolve(null);
    }

    if (this.user) {
      return Promise.resolve(this.user);
    }
    console.log('arriva qui');
    return this.userService.getUser().then(user => {
      console.log(user);
      return user;
    }).catch(error => {
      // tslint:disable-next-line: triple-equals
      if (error.status == 401) {
        console.log('arriva qui');
        localStorage.removeItem('token');
      }
      this.eventService.hideSpinner();
      return null;
    });
  }

  public logout() {
    this.user = null;
    localStorage.removeItem('token');
  }

}
