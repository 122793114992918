
<div class="login-borraccia">
  <div class="section">
    <div style="text-align: center;margin-top: 40px;">
      <span class="image-preview" style="background-size: cover;"
      [ngStyle]="{'background-image': 'url(' + bottleURL + ')'}">
  </span>
  </div>
  <!-- <hr> -->
  <div class="container" style="padding-top: 260px;">
    <p style=" border-top: 1px solid #CECECE; margin-top: 2px;margin-bottom: 25px;"></p>
    <p class="boxSummary">
        <span>{{ 'color_bottle' | translate }}</span>
        <span class="color">
            <span class="color-dot"
                [ngStyle]="{'background-color': configurationModel.water_bottle_color_hex}"></span>
            <span class="color-text">{{colorBottleName}}</span>
        </span>
    </p>
</div>
<div class="container ">
  <p class="boxSummary">
      <span>{{ capName }}</span>
      <span class="color">
          <span class="color-dot" [ngStyle]="{'background-color': configurationModel.cap_color_hex}"></span>
          <span class="color-text">{{colorCapName}}</span>
      </span>
  </p>
</div>
<div class="container ">
  <p class="boxSummary">
      <span>{{ valveName }}</span>
      <span class="color">
          <span class="color-dot" [ngStyle]="{'background-color': configurationModel.valve_color_hex}"></span>
          <span class="color-text">{{colorValveName}}</span>
      </span>
  </p>
</div>
<div class="container ">
  <p class="boxSummary">
      <span>{{ 'capacity_bottle' | translate }}</span>
      <span class="color">
          <span class="color-text">{{configurationModel.water_bottle_capacity}}ml</span>
      </span>
  </p>
</div>
<div class="container ">
  <p class="boxSummary">
      <span>{{ 'quantity_order' | translate }}</span>
      <span class="color">
          <span class="color-text">{{configurationModel.quantity}}pz</span>
      </span>
  </p>
</div>
  </div>
    <div class="login-borraccia-top">
     
    </div>
    
</div>
  
  <div class="columns is-gapless">
    <div class="column is-5">
  
      <div class="section">
        <div class="container is-fluid">
          <a href="/home"><img class="logo" src="/assets/img/login_logo.png"></a>
        </div>
        <div class="container is-fluid login">
          Login <img src="/assets/img/red_dot.png">
        </div>
        <div class="container is-fluid login-subtitle">
          {{ 'login_configuration_text' | translate }}
        </div>
        <div class="container is-fluid login-form">
          <div class="field">
            <label class="label">Email</label>
            <div class="control">
              <input class="input" type="email" placeholder="{{'form_email' | translate}}" [(ngModel)]="email">
            </div>
          </div>
  
          <div class="field">
            <label class="label password">Password</label>
            <div class="control">
              <input class="input" type="password" placeholder="{{'form_pw' | translate}}" [(ngModel)]="password" *ngIf="!showPassword">
              <input class="input" type="text" placeholder="{{'form_pw' | translate}}" [(ngModel)]="password" *ngIf="showPassword">
              <button class="eye" *ngIf="!showPassword" (click)="showHidePassword()"><i class="fas fa-eye"></i></button>
              <button class="eye" *ngIf="showPassword" (click)="showHidePassword()"><i class="fas fa-eye-slash"></i></button>
            </div>
          </div>
  
          <div class="field">
            <div class="control submit">
              <button class="button is-primary is-fullwidth" (click) ="goToQuotationRequest()">{{'login_access' | translate}}</button>
            </div>
          </div>
        </div>
  
        <div class="container is-fluid register">
          {{'registration_question' | translate}} <a (click) ="goToRegisterPage()"><strong>{{'registration_access' | translate}}</strong></a>
        </div>

        <div class="container is-fluid register">
          <a (click) ="goToPasswordRecoverPage()"><strong>{{'registration_forgot_password' | translate}}</strong></a>
        </div>
  
      </div>
  
    </div>
  </div>
  