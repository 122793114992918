import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { EventService } from 'src/app/services/event.service';
import { QuotationService } from 'src/app/services/quotation.service';

@Component({
  selector: 'app-feedback',
  templateUrl: './feedback.component.html',
  styleUrls: ['./feedback.component.scss']
})
export class FeedbackComponent implements OnInit {
  serial: string;
  order: boolean = false;
  constructor(private router: Router, private route: ActivatedRoute, private eventService: EventService, private quotationService: QuotationService) { }

  ngOnInit(): void {
    this.route.queryParams.subscribe(params => {
      if (params.order) {
        this.order = true;
      }
    });
    this.quotationService.serial.subscribe(serialRequest => this.serial = serialRequest);
    this.eventService.hideSpinner();
  }

  goBackHome() {
    this.eventService.showSpinner();
    this.router.navigate(['/home']);
  }

}
