import { Component, OnInit } from '@angular/core';
import { EventService } from 'src/app/services/event.service';

@Component({
  selector: 'app-text-popup',
  templateUrl: './text-popup.component.html',
  styleUrls: ['./text-popup.component.scss']
})
export class TextPopupComponent implements OnInit {

  visible = false;
  text = 'Loading...';

  constructor(private eventService: EventService) {}

  public ngOnInit(): void {
    this.eventService.textPopupStatus.subscribe(state => {
        this.visible = state;
    });
    this.eventService.textPopupText.subscribe(text => {
      this.text = text;
    });
  }

  layerClicked() {
    this.eventService.hideTextPopup();
  }

}
