import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { BehaviorSubject, Observable } from 'rxjs';
import { Configuration } from '../models/configuration';
import { ResponseGet } from './utils';

@Injectable()
export class ConfigurationService {
  constructor(private http: HttpClient) { }

  deleteConfiguration(configurationId: number): Promise<void> {
    // tslint:disable-next-line: max-line-length
    return this.http.delete<void>(environment.apiName + '/configurations/' + configurationId  , { headers: this.getRequestHeaders() }).toPromise();
  }

  getConfigurations(): Promise<ResponseGet> {
    return this.http.get<ResponseGet>(environment.apiName + '/configurations' ,
    { headers: this.getRequestHeaders()}).toPromise();
  }

  uploadConfiguration(configuration: Configuration): Promise<any> {
    return this.http.post<Configuration>(environment.apiName + '/configurations', configuration ,
    { headers: this.getRequestHeaders() }).toPromise();
  }

  uploadConfigurationAsset(confId: number, configuration: any, index: number): Promise<any> {
    return this.http.post<any>(environment.apiName + '/configurations/' + confId + '/assets/' + index, configuration,
    { headers: this.getRequestHeaders() }).toPromise();
  }

  sendConfigurationEmail(configurationId: number): Promise<any> {
    return this.http.post<any>(environment.apiName + '/configurations/' + configurationId + '/email', {},
    { headers: this.getRequestHeaders() }).toPromise();
  }

  updateConfiguration(confId: number) {
    return this.http.put<any>(environment.apiName + '/configurations/' + confId, {},
    { headers: this.getRequestHeaders() }).toPromise();
  }

  getRequestHeaders() {
    return new HttpHeaders(
        {
          'Content-Type': 'application/json',
          Accept: 'application/json',
          Authorization: 'Bearer ' + localStorage.getItem('token')
        }
    );
  }

}
