import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { faFacebookF } from '@fortawesome/free-brands-svg-icons/faFacebookF';
import { faTwitter } from '@fortawesome/free-brands-svg-icons/faTwitter';
import { FaIconLibrary } from '@fortawesome/angular-fontawesome';
import { EmailService } from 'src/app/services/email.service';
import { TranslateService } from '@ngx-translate/core';


const EMAIL_REGEXP = new RegExp(/^[a-zA-Z0-9.!#$%&'*+\/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/)

@Component({
  selector: 'app-share-modal',
  templateUrl: './share-modal.component.html',
  styleUrls: ['./share-modal.component.scss']
})
export class ShareModalComponent implements OnInit {

  @Input() url: string = "";
  @Input() configurationId: string = "";
  @Output() onClose = new EventEmitter;

  emailToContact: string = "";
  lang: string = "";
  mobile: boolean = false;

  constructor(
    private translate: TranslateService,
    private emailService: EmailService,
    library: FaIconLibrary,
  ) {
    library.addIcons(faFacebookF, faTwitter);
    if (window.screen.width < 768) { // 768px portrait
      this.mobile = true;
    }
  }

  ngOnInit(): void {
    this.lang = this.translate.getBrowserLang();
  }

  sendEmail() {
    
    const requestBody: Object = { 
      email: this.emailToContact,
      lang: this.lang 
    };

    if (EMAIL_REGEXP.test(this.emailToContact))
      this.emailService.postEmail(this.configurationId, requestBody)
        .then(() => { this.onClose.emit(null) })
        .catch(err => console.log(err))
    else
      console.log('INVALID_EMAIL')
  }

  cancel() {
    this.onClose.emit(null);
  }

}
