import { Component, OnInit, HostListener, Input, OnChanges, SimpleChanges, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { WaterBottle } from 'src/app/models/waterBottle';
import { LangChangeEvent, TranslateService } from '@ngx-translate/core';
import { Utils } from 'src/app/services/utils';
import { EventService } from 'src/app/services/event.service';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-home-cell',
  templateUrl: './home-cell.component.html',
  styleUrls: ['./home-cell.component.scss']
})
export class HomeCellComponent implements OnInit, OnChanges, OnDestroy {
  @Input() waterBottle: WaterBottle;

  name = '';
  description: SafeHtml;
  plainDescription = '';

  isSeparatorHidden = true;
  startLoader = false;

  private langSub: Subscription;

  constructor(
    private sanitizer: DomSanitizer,
    private router: Router,
    public translate: TranslateService,
    private utils: Utils,
    private eventService: EventService) { }

  ngOnInit(): void {
    this.langSub = this.translate.onLangChange.subscribe((event: LangChangeEvent) => {
      this.updateDescription();
    });
  }

  ngOnDestroy() {
    this.langSub.unsubscribe();
  }

  @HostListener('mouseenter')
  onMouseEnter() {
    this.isSeparatorHidden = false;
  }

  @HostListener('mouseleave')
  onMouseLeave() {
    this.isSeparatorHidden = true;
  }

  goToConfigurator() {
    this.eventService.showSpinner();
    this.router.navigate(['/configurator'], { queryParams: { id: this.waterBottle.id } });
  }

  ngOnChanges(changes: SimpleChanges) {
    this.name = this.waterBottle.variant;
    this.updateDescription();
    this.description = this.utils.getTextForKey(this.waterBottle.texts, 'water_bottle', 'description').replace(/<[^>]*>/g, '');
  }

  private updateDescription() {
    if (!this.waterBottle) {
      return;
    }
    this.plainDescription = this.utils.getTextForKey(this.waterBottle.texts, 'water_bottle', 'description');
    this.description = this.sanitizer.bypassSecurityTrustHtml(this.plainDescription);
  }

  moreClicked(event: MouseEvent) {
    event.stopImmediatePropagation();
    this.eventService.setTextPopupText(this.plainDescription);
    this.eventService.showTextPopup();
  }

}
