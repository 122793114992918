import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { environment } from '../../environments/environment';
import { Quotation } from '../models/quotation';
import { ResponseGet } from './utils';

@Injectable()
export class QuotationService {

  private quotationCode = new BehaviorSubject<string>('');
  private quotationSerial = new BehaviorSubject<string>('');
  private quotationCapColor = new BehaviorSubject<string>('');
  private quotationValveColor = new BehaviorSubject<string>('');
  private quotationBottleColor = new BehaviorSubject<string>('');
  private quotationCapacity = new BehaviorSubject<number>(0);
  private quotationQuantity = new BehaviorSubject<number>(0);
  private quotationPrice = new BehaviorSubject<number>(0);
  private quotationCapID = new BehaviorSubject<number>(0);
  private quotationBottleID = new BehaviorSubject<number>(0);
  private quotationImages = new BehaviorSubject<Array<string>>([]);
  private quotationWaterBottleImage = new BehaviorSubject<string>('');
  colorCap = this.quotationCapColor.asObservable();
  colorBottle = this.quotationBottleColor.asObservable();
  colorValve = this.quotationValveColor.asObservable();
  capacity = this.quotationCapacity.asObservable();
  quantity = this.quotationQuantity.asObservable();
  price = this.quotationPrice.asObservable();
  capId = this.quotationCapID.asObservable();
  bottleId = this.quotationBottleID.asObservable();
  images = this.quotationImages.asObservable();
  waterBottleImage = this.quotationWaterBottleImage.asObservable();
  code = this.quotationCode.asObservable();
  serial = this.quotationSerial.asObservable();


  constructor(private http: HttpClient) { }
  setCode(code: string) {
    this.quotationCode.next(code);
  }
  setImages(img: any) {
    this.quotationImages.next(img);
  }
  setWaterBottleImage(img: string) {
    this.quotationWaterBottleImage.next(img);
  }
  setColorCap(color: string) {
    this.quotationCapColor.next(color);
  }
  setColorBottle(color: string) {
    this.quotationBottleColor.next(color);
  }
  setColorValve(color: string) {
    this.quotationValveColor.next(color);
  }
  setCapacity(capacity: number) {
    this.quotationCapacity.next(capacity);
  }
  setQuantity(quantity: number) {
    this.quotationQuantity.next(quantity);
  }
  setPrice(price: number) {
    this.quotationPrice.next(price);
  }
  setCapID(id: number) {
    this.quotationCapID.next(id);
  }
  setBottleID(id: number) {
    this.quotationBottleID.next(id);
  }
  setSerial(serial: string) {
    this.quotationSerial.next(serial);
  }

  getQuotations(web?: boolean, pageNumber?: any ): Promise<ResponseGet> {
    return this.http.get<ResponseGet>(environment.apiName + '/quotations' ,
    { headers: this.getRequestHeaders(), params: { webPage: web.toString(), page: pageNumber} }).toPromise();
  }
/*
  deleteQuotation(quotationId: number): Promise<void> {
    return this.http.delete<void>(environment.apiName + '/quotations/' + quotationId  , { headers: this.getRequestHeaders() }).toPromise();
  } */

  uploadQuotation(quotation: Quotation): Promise<any> {
    return this.http.post<Quotation>(environment.apiName + '/quotations', quotation , { headers: this.getRequestHeaders() }).toPromise();
  }

  uploadQuotationAsset(quotId: number, quotation: any, index: number): Promise<any> {
    return this.http.post<any>(environment.apiName + '/quotations/' + quotId + '/assets/' + index, quotation,
    { headers: this.getRequestHeaders() }).toPromise();
  }

  postQuotationEmail(quotation: any): Promise<any> {
    // tslint:disable-next-line: max-line-length
    return this.http.post<any>(environment.apiName + '/quotations/email', quotation, { headers: this.getRequestHeaders() }).toPromise();
  }

  getRequestHeaders() {
    const headers = new HttpHeaders(
        {
          'Content-Type': 'application/json',
          Accept: 'application/json'
        }
    );
    if (localStorage.getItem('token')) {
      headers.append('Authorization', 'Bearer ' + localStorage.getItem('token'));
    }
    return headers;
  }


}
