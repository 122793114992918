<div *ngIf="spinnerVisible" class="layer">
  <div class="spinner">
    <div style="margin-top: 24px;">
    <img class="spinner-img" src="/assets/img/red_logo_bar.png">
  </div>
  <div style="margin-top: -9px; margin-right: 10px;">
    <img class="spinner-img2" src="/assets/img/red_logo_bar.png">
  </div>
  <div style="margin-top: -9px; margin-right: 21px;">
    <img class="spinner-img3" src="/assets/img/red_logo_bar.png">
  </div>
  <div style="margin-top: 10px;">{{ text }}</div>
</div>
