import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Subject } from 'rxjs';

@Injectable()
export class EventService {

  private showSpinnerEvent = new BehaviorSubject<boolean>(false);
  private spinnerTextEvent = new BehaviorSubject<string>('Loading...');
  private showTextPopupEvent = new BehaviorSubject<boolean>(false);
  private textPopupTextEvent = new BehaviorSubject<string>('Loading...');
  private saveEvent = new Subject<void>();
  private saveCompleteEvent = new Subject<void>();

  public showSpinner() {
    this.showSpinnerEvent.next(true);
  }

  public hideSpinner() {
      this.showSpinnerEvent.next(false);
  }

  public showTextPopup() {
    this.showTextPopupEvent.next(true);
  }

  public hideTextPopup() {
    this.showTextPopupEvent.next(false);
  }

  public get spinnerStatus(): Observable<boolean> {
      return this.showSpinnerEvent.asObservable();
  }

  public get textPopupStatus(): Observable<boolean> {
    return this.showTextPopupEvent.asObservable();
}

  public setSpinnerText(text: string) {
    this.spinnerTextEvent.next(text);
  }

  public get spinnerText(): Observable<string> {
      return this.spinnerTextEvent.asObservable();
  }

  public setTextPopupText(text: string) {
    this.textPopupTextEvent.next(text);
  }

  public get textPopupText(): Observable<string> {
      return this.textPopupTextEvent.asObservable();
  }

  public save() {
    this.saveEvent.next();
  }

  public get getSave(): Observable<void> {
    return this.saveEvent.asObservable();
  }

  public saveComplete() {
    this.saveCompleteEvent.next();
  }

  public get getSaveComplete(): Observable<void> {
    return this.saveCompleteEvent.asObservable();
  }
}
