import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';

import { ShareButtonModule } from 'ngx-sharebuttons/button'

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { LoginGuardService } from './services/login-guard.service';
import { LoginComponent } from './pages/login/login.component';
import { HomeComponent } from './pages/home/home.component';
import { TopBarComponent } from './pages/top-bar/top-bar.component';
import { BaseComponent } from './pages/base/base.component';
import { HomeCellComponent } from './pages/home/home-cell/home-cell.component';
import { UserDataService } from './services/user-data.service';
import { UserService } from './services/user.service';
import { RegisterComponent } from './pages/register/register.component';
import { ConfiguratorComponent } from './pages/configurator/configurator.component';
import { ConfiguratorPageComponent } from './pages/configurator/configurator-page/configurator-page.component';
import { LoginConfigurationComponent } from './pages/login-configuration/login-configuration.component';
import { QuotationRequestComponent } from './pages/quotation-request/quotation-request.component';
import { FeedbackComponent } from './pages/feedback/feedback.component';
import { ConfigurationsComponent } from './pages/configurations/configurations.component';
import { MainService } from './services/main.service';
import { Utils } from './services/utils';
import { EngineComponent } from './components/engine.component';
import { DropZoneDirective } from './directives/drop-zone.directive';
import { CustomizationAreaComponent } from './components/customization-area/customization-area.component';
import { CustomizationAreaImageComponent } from './components/customization-area-image/customization-area-image.component';
import { CustomizationAreaTextComponent } from './components/customization-area-text/customization-area-text.component';
import { EventService } from './services/event.service';
import { SpinnerComponent } from './components/spinner/spinner.component';
import { ColorPickerModule } from 'ngx-color-picker';
import { QuotationService } from './services/quotation.service';
import { ConfigurationService } from './services/configuration.service';
import { EllipsisModule } from 'ngx-ellipsis';
import { TextPopupComponent } from './components/text-popup/text-popup.component';
import { ShareModalComponent } from './components/share-modal/share-modal.component';
import { FaIconLibrary } from '@fortawesome/angular-fontawesome';
import { RegisterConfigurationComponent } from './pages/register-configuration/register-configuration.component';
import { PasswordRecoverComponent } from './pages/password-recover/password-recover.component';
import { PasswordSentComponent } from './pages/password-sent/password-sent.component';

// i18n support
export function createTranslateLoader(http: HttpClient, baseHref) {
  // Temporary Azure hack
  if (baseHref == null && typeof window !== 'undefined') {
    baseHref = window.location.origin;
  }
  if (baseHref == null) {
    baseHref = '';
  }
  // i18n files are in `wwwroot/assets/`
  return new TranslateHttpLoader(http, `${baseHref}/assets/i18n/`, '.json');
}

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    HomeComponent,
    TopBarComponent,
    BaseComponent,
    HomeCellComponent,
    RegisterComponent,
    ConfiguratorComponent,
    ConfiguratorPageComponent,
    LoginConfigurationComponent,
    QuotationRequestComponent,
    FeedbackComponent,
    ConfigurationsComponent,
    EngineComponent,
    DropZoneDirective,
    CustomizationAreaComponent,
    CustomizationAreaImageComponent,
    CustomizationAreaTextComponent,
    SpinnerComponent,
    TextPopupComponent,
    ShareModalComponent,
    RegisterConfigurationComponent,
    PasswordRecoverComponent,
    PasswordSentComponent
  ],
  imports: [
    BrowserModule,
    HttpClientModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: (createTranslateLoader),
        deps: [HttpClient]
      }
    }),
    AppRoutingModule,
    ShareButtonModule,
    FormsModule,
    ReactiveFormsModule,
    ColorPickerModule,
    EllipsisModule
  ],
  providers: [
    TranslateModule,
    LoginGuardService,
    UserDataService,
    UserService,
    MainService,
    Utils,
    EventService,
    QuotationService,
    ConfigurationService
  ],
  bootstrap: [AppComponent]
})
export class AppModule {

}
