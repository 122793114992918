<div class="button-container">
  <div class="tooltip">
    <button class="button" (click)="fileInput.click()"><img src="/assets/img/icon__image.png">
      <div class="top">
        <h3>{{'tooltip_image' | translate}}</h3>
        <i></i>
      </div>
    </button>
  </div>
  <div class="tooltip">
    <button class="button" (click)="addText()"><img src="/assets/img/icone__text.png">
      <div class="top2">
        <h3>{{'tooltip_text' | translate}}</h3>
        <i></i>
      </div>
    </button>
  </div>
  <div class="tooltip">
    <button class="button" (click)="remove()"><i class="fas fa-trash-alt"></i>
      <div class="top3">
        <h3>{{'tooltip_del' | translate}}</h3>
          <i></i>
      </div>
    </button>
  </div>
  <div class="tooltip">
    <button class="button" (click)="moveToTop()"><i class="fas fa-arrow-up"></i>
      <div class="top4">
        <h3>{{'tooltip_swipeUp' | translate}}</h3>
        <i></i>
      </div>
    </button>
  </div>
  <div class="tooltip">
    <button class="button" (click)="moveToBottom()"><i class="fas fa-arrow-down"></i>
      <div class="top5">
        <h3>{{'tooltip_swipeDown' | translate}}</h3>
        <i></i>
      </div>
    </button>
  </div>
  <!-- <button class="button" (click)="addText()"><i class="fas fa-font"></i></button> -->
  <!-- <button class="button" (click)="test()"><i class="fas fa-save"></i></button> -->
  <input #fileInput type="file" (change)="fileSelected($event)" style="display: none;"/>
</div>

<div #area contenteditable="true" class="area" appDropZone (fileDrop)="onFileDrop($event)" 
    (mousedown)="onMouseDown($event)" (touchstart)="onTouchStart($event)" (mousemove)="onMouseMove($event)" (touchmove)="onTouchMove($event)" (mouseup)="onMouseUp($event)" (touchend)="onMouseUp($event)" (mouseleave)="onMouseLeave($event)" (keydown)="onKeyDown($event)">
  
  <div class="container" #parent>
    <div #container></div>
  </div>

  <div class="handle" #topLeftHandle></div>
  <div class="handle" #topRightHandle></div>
  <div class="handle" #bottomLeftHandle></div>
  <div class="handle" #bottomRightHandle></div>

  <div class="topBanner"></div>
  <div class="bottomBanner"></div>
  <div class="leftBanner"></div>
  <div class="rightBanner"></div>

  <div class="outer-dashed-layer"></div>
  <div class="inner-dashed-layer"></div>

  <div class="text-box" #textBox>
    <div class="text-popup" *ngIf="textAreaSelected">
      <div class="text-button"
        [ngStyle]="{'background-image': 'url(/assets/img/icon__border-horizontal.png)'}"
        [ngClass]="{'selected': textAreaSelected.horizontal}"
        (click)="horizontalPressed()"></div>

      <div class="text-button"
        [ngStyle]="{'background-image': 'url(/assets/img/icon__border-vertical.png)'}"
        [ngClass]="{'selected': !textAreaSelected.horizontal}"
        (click)="verticalPressed()"></div>

      <div class="text-button"
        [ngStyle]="{'background-image': 'url(/assets/img/icon__bold.png)'}" 
        [ngClass]="{'selected': textAreaSelected.textBold}"
        style="margin-left: 15px;"
        (click)="boldPressed()"></div>

      <div class="text-button"
        [ngStyle]="{'background-image': 'url(/assets/img/icon__italic.png)'}"
        [ngClass]="{'selected': textAreaSelected.textItalic}"
        (click)="italicPressed()"></div>

      <div class="text-button"
        [ngStyle]="{'background-image': 'url(/assets/img/icon__underline.png)'}"
        [ngClass]="{'selected': textAreaSelected.textUnderline}"
        (click)="underlinePressed()"></div>

      <div class="text-button"
        [ngStyle]="{'background-image': 'url(/assets/img/icon__icon__strikethrough.png)'}"
        [ngClass]="{'selected': textAreaSelected.textStrikethrough}"
        (click)="strikethroughPressed()"></div>

      <div class="text-button text-button-font">
        <div class="select is-small">
          <select [(ngModel)]="textAreaSelected.fontFamily" (change)="fontChanged()">
            <option *ngFor="let font of fonts" value="{{ font }}" >{{ font }}</option>
          </select>
        </div>
      </div>

      <div class="text-button-input">
        <input class="input is-small" type="number" [(ngModel)]="textAreaSelected.fontSizePixel" (change)="fontSizeChanged()" (focus)="onFocus($event)" (blur)="onBlur($event)">
      </div>

      <div class="text-button-color"
        style="margin-right: 10px;">

        <div class="text-button-color-inner"
          [(colorPicker)]="color"
          [style.background]="color"
          (colorPickerChange)="textColorChanged()"></div>

      </div>

      <div class="text-button-separator"></div>

      <div class="text-button"
        [ngStyle]="{'background-image': 'url(/assets/img/icon__close_grey.png)'}"
        style="margin-left: 10px;"
        (click)="onTextBoxEnter()"></div>
    </div>

    <div class="text-popup-arrow">
      <img src="/assets/img/arrow_down.png">
    </div>

    <input type="text" [(ngModel)]="text" (keyup.enter)="onTextBoxEnter()" (ngModelChange)="textChanged()" (focus)="onFocus($event)" (blur)="onBlur($event)" #textBoxInput>
  </div>
</div>
