import { Component } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { UserDataService } from './services/user-data.service';
import { Router, NavigationEnd } from '@angular/router';
import { environment } from 'src/environments/environment';

declare let ga: Function;

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'borracce-frontend';

  constructor(
    public translate: TranslateService,
    public userDataService: UserDataService,
    public router: Router
  ) {
    const lang = navigator.language.substr(0, 2);
    translate.use(lang);

    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        if (environment.production) {
          ga('set', 'page', event.urlAfterRedirects);
          ga('send', 'pageview');
        }
      }
    });
  }

}
