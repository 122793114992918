import { Directive, Output, EventEmitter, HostListener } from '@angular/core';

@Directive({
  selector: '[appDropZone]',
})
export class DropZoneDirective {
  @Output() public fileDrop = new EventEmitter<any>();
  @Output() public fileDragOver = new EventEmitter<any>();
  @Output() public fileDragLeave = new EventEmitter<any>();

  constructor() {}

  @HostListener('drop', ['$event'])
  public onDrop(event: any): void {
    event.preventDefault();
    event.stopPropagation();
    this.fileDrop.emit(event.dataTransfer.files);
  }

  @HostListener('dragover', ['$event'])
  public onDragOver(event: any): void {
    event.preventDefault();
    event.stopPropagation();
    this.fileDragOver.emit();
  }

  @HostListener('dragleave', ['$event'])
  public onDragLeave(event: any): void {
    event.preventDefault();
    event.stopPropagation();
    this.fileDragLeave.emit();
  }
}
