
<div class="page">

  <div class="search-bar-box" #searchBar>
    <div class="search-bar-container">
      <img class="icon" src="/assets/img/icon__search.png">
      <input [(ngModel)]="searchText" name="text" (keydown.enter)="search()" class="search" placeholder="{{'home_search' | translate}}">
    </div>
  </div>

  <div class="section" #section>
    <div class="container is-fluid banner">
      <div class="home-title" [innerHTML]="'home_page_title' | translate">
      </div>
      <div class="home-subtitle" [innerHTML]="'home_page_subtitle' | translate">
      </div>
    </div>
  
    <div class="container elements">
      <div class="columns is-multiline is-mobile">
        <div class="column is-one-quarter-tablet is-half-mobile" *ngFor="let element of variants">
          <app-home-cell [waterBottle]="element[0]"></app-home-cell>
        </div>
      </div>
    </div>
  </div>
</div>

 