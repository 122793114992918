<div class="modal is-active">
    <div class="modal-background"></div>
    <div class="modal-card">
        <header id="mobile" class="modal-card-head">
            <p class="modal-card-title"></p>
            <button class="delete" aria-label="close" (click)="cancel()"></button>
          </header>
        <section class="modal-card-body">
            <div class="delete-button">
                <button class="delete" aria-label="close" (click)="cancel()"></button>
            </div>
            <div class="image">
                <img class="img-bottle" src="/assets/img/img-drinking_bottle.png">
            </div>
            <div class="text">
                <div class="title-container">
                    <h1 class="title">{{ 'congrats_title' | translate}}, </h1>
                    <h1 class="title">{{ 'configuration_saved' | translate}}</h1>
                </div>
                <div class="social-share-container">
                    <p class="content">{{ 'share_on_socials' | translate}}</p>
                    <share-button 
                        class="share-button"
                        [size]="4"
                        [theme]="'circles-dark'"
                        [button]="'facebook'"
                        [url]="url"
                        [autoSetMeta]="false"
                    >
                </share-button>
                    <share-button 
                        class="share-button"
                        [size]="4"
                        [theme]="'circles-dark'"
                        [button]="'twitter'"
                        [url]="url"
                    ></share-button>
                </div>
                <div class="email-share-container">
                    <p class="content">{{ 'share_via_email' | translate}}</p>
                    <div class="field has-addons">
                        <div class ="control is-expanded">
                            <input class="input" type="email" [(ngModel)]="emailToContact" type="text">
                        </div>
                        <div class ="control" id="desktop">
                            <button (click)="sendEmail()" class="button is-black">Share now</button>
                        </div>
                    </div>
                    <div id="mobile">
                        <button (click)="sendEmail()" class="button is-black">Share now</button>
                    </div>
                </div>
            </div>
        </section>
    </div>
</div>