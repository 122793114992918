<div class="login-borraccia">
  <div class="login-borraccia-top">
  </div>
</div>

<div class="columns is-gapless">
  <div class="column is-5">

    <div class="section">
      <div class="container is-fluid">
        <a href="/home"><img class="logo" src="/assets/img/login_logo.png"></a>
      </div>
      <div class="container is-fluid login">
        {{ 'password_sent_title' | translate }} <img src="/assets/img/red_dot.png">
      </div>
      <div class="container is-fluid login-subtitle">
        {{ 'password_sent_subtitle' | translate }}
      </div>

      <div class="container is-fluid register">
        <a (click) ="goToLoginPage()"><strong>{{'back_to_login' | translate}}</strong></a>
      </div>
    </div>

  </div>
</div>
