import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class EmailService {

  constructor(private http: HttpClient) { }

  postEmail(configurationId: string, requestBody: object) {

    const requestHeader = new HttpHeaders()
      .set('Content-Type', 'application/json')
      .set('Accept', 'application/json')
      .set('Authorization', 'Bearer ' + localStorage.getItem('token'))

    return this.http.post(environment.apiName + `/configurations/${configurationId}/share`, requestBody, { headers: requestHeader }).toPromise()

  }
}
