export interface Color {
  id?: number;
  name?: string;
  hex?: string;
  insert_date?: string;
  modify_date?: string;
}

export const colorNameMapping = {
  '#ffffff': {
    'en': 'WHITE',
    'it': 'BIANCO'
  },
  '#000000': {
    'en': 'BLACK',
    'it': 'NERO'
  },
  '#54565a': {
    'en': 'COOL GREY 11C',
    'it': 'COOL GREY 11C'
  },
  '#a2a9ad': {
    'en': '429C',
    'it': '429C'
  },
  '#ffd600': {
    'en': '012C',
    'it': '012C'
  },
  '#ff8300': {
    'en': '151C',
    'it': '151C'
  },
  '#ff5100': {
    'en': '021C',
    'it': '021C'
  },
  '#e1251b': {
    'en': '485C',
    'it': '485C'
  },
  '#f44c7f': {
    'en': '191C',
    'it': '191C'
  },
  '#c028B9': {
    'en': 'PURPLE C',
    'it': 'PURPLE C'
  },
  '#0a2240': {
    'en': '289C',
    'it': '289C'
  },
  '#001689': {
    'en': 'REFLEX BLUE C',
    'it': 'REFLEX BLUE C'
  },
  '#0075c9': {
    'en': '3005C',
    'it': '3005C'
  },
  '#008dd1': {
    'en': '2193C',
    'it': '2193C'
  },
  '#00754a': {
    'en': '341C',
    'it': '341C'
  },
  '#38d430': {
    'en': '802C',
    'it': '802C'
  },
  '#cddb00': {
    'en': '381C',
    'it': '381C'
  },
  '#dfdd00': {
    'en': 'YELLOW FLUO',
    'it': 'GIALLO FLUO'
  }
};
