import { Component, OnInit } from '@angular/core';
import { EventService } from 'src/app/services/event.service';
import { AuthRequest } from 'src/app/models/authRequest';
import { UserService } from 'src/app/services/user.service';
import { UserDataService } from 'src/app/services/user-data.service';
import { Router } from '@angular/router';
import { QuotationService } from 'src/app/services/quotation.service';
import { MainService } from 'src/app/services/main.service';
import { TranslateService } from '@ngx-translate/core';
import { Configuration } from 'src/app/models/configuration';
import { Quotation } from 'src/app/models/quotation';
import { Utils } from 'src/app/services/utils';

@Component({
  selector: 'app-login-configuration',
  templateUrl: './login-configuration.component.html',
  styleUrls: ['./login-configuration.component.scss']
})
export class LoginConfigurationComponent implements OnInit {
  email: string;
  password: string;
  configurationModel: Configuration;
  colorCap: string;
  colorBottle: string;
  colorCapName: string;
  colorBottleName: string;
  colorValveName: string;
  capacity: number;
  quantity: number;
  images = [];
  filesToUpload = [];
  quotationModel: Quotation;
  capName = '';
  valveName = '';
  bottleURL: any;
  showPassword = false;
  constructor(private eventService: EventService, private utils: Utils, private userService: UserService,
              private quotationService: QuotationService,
              private userDataService: UserDataService, private router: Router, private mainService: MainService,
              private translate: TranslateService) { }

  ngOnInit(): void {
    this.configurationModel = {};
    this.bottleURL = null;
    this.configurationModel.images = [];
    this.quotationService.colorCap.subscribe(color => this.configurationModel.cap_color_hex = color);
    this.quotationService.colorBottle.subscribe(color => this.configurationModel.water_bottle_color_hex = color);
    this.quotationService.colorValve.subscribe(color => this.configurationModel.valve_color_hex = color);
    this.quotationService.capacity.subscribe(capacity => this.configurationModel.water_bottle_capacity = capacity);
    this.quotationService.quantity.subscribe(quantity => this.configurationModel.quantity = quantity);
    this.quotationService.capId.subscribe(id => {
      this.configurationModel.cap_id = id;
      this.mainService.getCap(id).then(cap => {
        const capName = this.translate.instant('color_cap', {cap: this.utils.getTextForKey(cap.texts, 'cap', 'name')});
        this.capName = capName.substr(0, 1).toUpperCase() + capName.substring(1).toLowerCase();
        const valveName = this.translate.instant('color_valve', {valve: this.utils.getTextForKey(cap.texts, 'valve', 'name')});
        this.valveName = valveName.substr(0, 1).toUpperCase() + valveName.substring(1).toLowerCase();
      }).catch(error => {
        this.eventService.hideSpinner();
      });
    });
    this.quotationService.bottleId.subscribe(id => this.configurationModel.water_bottle_id = id);
    let array = [];
    let img = null;
    this.quotationService.images.subscribe(images => array = images);
    this.quotationService.waterBottleImage.subscribe(image => img = image);
    this.utils.getColors().then(colors => {
       const colorCap = colors.filter(element => {
        return element.hex === this.configurationModel.cap_color_hex;
      });
       this.colorCapName = colorCap[0].name;
       const colorBottle = colors.filter(element => {
        return element.hex === this.configurationModel.water_bottle_color_hex;
      });
       this.colorBottleName = colorBottle[0].name;
       const colorValve = colors.filter(element => {
        return element.hex === this.configurationModel.valve_color_hex;
      });
       this.colorValveName = colorValve[0].name;
    }).catch(error => {this.eventService.hideSpinner();});
    array.forEach(element => {
      this.configurationModel.images.push(element.srcImage);
    });

    this.filesToUpload = array;
    // console.log(img);
    if ( img !== '') {
      const reader = new FileReader();

      const block = img.split(';');
  // Get the content type of the image
      const contentType = block[0].split(':')[1]; // In this case "image/png"
  // get the real base64 content of the file
      const realDatabase64 = block[1].split(',')[1];
      const blob = this.b64toBlob(realDatabase64, contentType);
      reader.readAsDataURL(blob);
      reader.onloadend = () => {
        const base64data = reader.result;
        const src = '' + base64data;
        const obj = {
          srcImage: src,
        };
        this.bottleURL = obj.srcImage;
        this.filesToUpload.push(obj);
        console.log(this.filesToUpload);
      };
    } else {
      const image = array.filter(arr => {
        return arr.area_index === 0;
      });
      if (image[0]) {
        console.log(image[0]);
        this.bottleURL = image[0].image_url;
      }
    }
    this.eventService.hideSpinner();
  }

  goToQuotationRequest() {
    this.eventService.showSpinner();
    const auth: AuthRequest = {
      email: this.email,
      password: this.password
    };
    this.userService.auth(auth).then(token => {
      localStorage.setItem('token', token.accessToken);
      this.userDataService.checkUser().then(user => {
        if (user !== null) {
          this.router.navigate(['/quotation-request']);
        } else {
          this.eventService.hideSpinner();
        }
      }).catch(error => {
        console.log(error);
        this.eventService.hideSpinner();
        return null;
      });
    }).catch(error => {
      console.log(error);
      this.eventService.hideSpinner();
      return null;
    });
  }

  goToRegisterPage() {
    this.router.navigate(['/register-configuration']);
  }

  goToPasswordRecoverPage() {
    this.router.navigate(['/password-recover'], {queryParams: {src: 'quotation'}});
  }

  b64toBlob(b64Data, contentType, sliceSize?) {
    contentType = contentType || '';
    sliceSize = sliceSize || 512;

    const byteCharacters = atob(b64Data);
    const byteArrays = [];

    for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
        const slice = byteCharacters.slice(offset, offset + sliceSize);

        const byteNumbers = new Array(slice.length);
        for (let i = 0; i < slice.length; i++) {
            byteNumbers[i] = slice.charCodeAt(i);
        }

        const byteArray = new Uint8Array(byteNumbers);

        byteArrays.push(byteArray);
    }

    const blob = new Blob(byteArrays, {type: contentType});
    return blob;
  }

  showHidePassword() {
    this.showPassword = !this.showPassword;
  }
}
