
<app-share-modal *ngIf='showModal' [url]="urlToShare" [configurationId]="configurationId" (onClose)="showModal=false"></app-share-modal>

<div class="section">
  <div class="container title">
    {{ 'my_configurations' | translate }}
   <img src="/assets/img/red_dot.png">
  </div>
</div>

<div class="section">
<!--   <div > -->
    <div class="columns login-borraccia" style="flex-wrap: wrap;">
      <div *ngFor="let element of elements" class="column is-one-quarter-tablet section">
        <div style="text-align: center;margin-top: 18px;">
          <img style="max-width: 80%;" src="{{element.image}}">
        </div>
        <div class="container">
          <p style=" border-top: 1px solid #F0F3F3; margin-top: 20px;margin-bottom: 7px;"></p>
          <div>
            <span class="color-text1">{{ 'color_bottle' | translate }}</span>
          </div>
          <div>
            <span class="color-text2">{{element.water_bottle_color_name}}</span>
            <span class="color-dot" [ngStyle]="{'background-color': element.water_bottle_color_hex}"></span>
          </div>
        </div>
        <div class="container">
          <div>
            <span class="color-text1">{{ element.capName }}</span>
          </div>
          <div>
            <span class="color-text2">{{element.cap_color_name}}</span>
            <span class="color-dot" [ngStyle]="{'background-color': element.cap_color_hex}"></span>
          </div>
        </div>
        <div class="container">
          <div>
            <span class="color-text1">{{ element.valveName }}</span>
          </div>
          <div>
            <span class="color-text2">{{element.valve_color_name}}</span>
            <span class="color-dot" [ngStyle]="{'background-color': element.valve_color_hex}"></span>
          </div>
        </div>
        <div class="container">
          <div>
            <span class="color-text1">{{ 'capacity_bottle' | translate }}</span>
          </div>
          <div>
            <span class="color-text2">{{element.water_bottle_capacity}}ml</span>
          </div>
        </div>
        <div class="container">
          <div>
            <span class="color-text1">{{ 'quantity_order' | translate }}</span>
          </div>
          <div>
            <span class="color-text2">{{element.quantity}}pz</span>
          </div>
        </div>
        <div *ngIf="element.price" class="container">
          <div>
            <span class="color-text1">{{ 'order_price' | translate }}</span>
          </div>
          <div>
            <span class="color-text2">{{element.price}}€</span>
          </div>
        </div>

        <div class="container">
          <div style="display: flex;">
            <div style="flex-grow: 1;">
              <span (click)="goToRequestQuot(element)" *ngIf="element.status == 'draft'" style="background-color: #E2001A;color: white;" class="quotation">{{ 'quotation_request' | translate }}</span>

              <span *ngIf="element.quotation_code" class="quotation_code">{{ element.quotation_code }}</span>
            </div>

            <div class="share-dialog" (click)="openShareModal(element)" style="cursor: pointer;">
              <img class="logo" src="/assets/img/icon-share-2.png">
            </div>

            <div class="image" (click)="deleteElement(element)" style="cursor: pointer;">
              <img class="logo" src="/assets/img/icon-trash.png">
            </div>
          </div>
        </div>

      </div>
    </div>
<!--   </div> -->
</div>

<div class="modal" [ngClass]="{'is-active': showDeleteModal}">
  <div class="modal-background"></div>
  <div class="modal-content">
    <div class="box">
      <div class="content">
        <p>
          <strong>{{ 'are_you_sure' | translate }}</strong>
          <br>
          {{ 'delete_configuration_message' | translate }}
        </p>
        <div class="columns">
          <div class="column">
            <div class="buttons">
              <button class="button" (click)="hideDeleteModal()">{{ 'cancel' | translate }}</button>
            </div>
          </div>
          <div class="column">
            <div class="buttons is-right">
              <button class="button is-danger" (click)="deleteElementConfirmed()">{{ 'delete' | translate }}</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>