<div class="login-borraccia">
  <div class="login-borraccia-top">
  </div>
</div>

<div class="columns is-gapless">
  <div class="column is-5">

    <div class="section">
      <div class="container is-fluid">
        <a href="/home"><img class="logo" src="/assets/img/login_logo.png"></a>
      </div>
      <div class="container is-fluid login">
        Login <img src="/assets/img/red_dot.png">
      </div>
      <div class="container is-fluid login-subtitle">
        {{ 'login_page_title' | translate }}
      </div>
      <div class="container is-fluid login-form">
        <div class="field">
          <label class="label">Email</label>
          <div class="control">
            <input class="input" type="email" [(ngModel)]="email">
          </div>
        </div>

        <div class="field">
          <label class="label password">Password</label>
          <div class="control">
            <input class="input" type="password" [(ngModel)]="password" *ngIf="!showPassword">
            <input class="input" type="text" [(ngModel)]="password" *ngIf="showPassword">
            <button class="eye" *ngIf="!showPassword" (click)="showHidePassword()"><i class="fas fa-eye"></i></button>
            <button class="eye" *ngIf="showPassword" (click)="showHidePassword()"><i class="fas fa-eye-slash"></i></button>
          </div>
        </div>

        <div class="field">
          <div class="error" *ngIf="error">{{ 'error_wrong_credentials' | translate }}</div>
        </div>

        <div class="field">
          <div class="control submit">
            <button class="button is-primary is-fullwidth" (click)="login()">{{'login_access' | translate}}</button>
          </div>
        </div>
      </div>

      <div class="container is-fluid register">
        {{'registration_question' | translate}} <a (click) ="goToRegisterPage()"><strong>{{'registration_access' | translate}}</strong></a>
      </div>

      <div class="container is-fluid register">
        <a (click) ="goToPasswordRecoverPage()"><strong>{{'registration_forgot_password' | translate}}</strong></a>
      </div>
    </div>

  </div>
</div>
