<div class="register-borraccia">
    <div class="register-borraccia-top">
    </div>
    <!-- <div class="register-borraccia-title">
        {{'image_title' | translate}}
    </div>
    <div class="register-borraccia-subtitle">
        {{'image_subtitle' | translate}}
    </div>
    <div class="register-borraccia-dots">
        <img class="logo" src="/assets/img/red_slash_dot.png">
        <img class="logo" src="/assets/img/gray_slash_dot.png">
        <img class="logo" src="/assets/img/gray_slash_dot.png">
        <img class="logo" src="/assets/img/gray_slash_dot.png">
    </div> -->
</div>

<div class="columns is-gapless">
    <div class="column is-5">

        <div class="section">
            <div class="container is-fluid">
                <a href="/home"><img class="logo" src="/assets/img/login_logo.png"></a>
            </div>
            <div class="container is-fluid register">
                <p>{{ 'registration_page_title' | translate }} <img src="/assets/img/red_dot.png"></p>
            </div>
            <div class="container is-fluid register-subtitle">
                <p>{{ 'registration_page_subtitle' | translate }}</p>
            </div>
            <div class="container is-fluid register-form">
                <div class="field">
                    <label class="label ">{{ 'request_form_name' | translate }}</label>
                    <div class="control">
                        <input class="input" type="text" placeholder="{{'form_name' | translate}}" [(ngModel)]="firstName" required>
                    </div>
                </div>

                <div class="field">
                    <label class="label surname">{{ 'request_form_surname' | translate }}</label>
                    <div class="control">
                        <input class="input" type="text" placeholder="{{'form_surname' | translate}}" [(ngModel)]="lastName" required>
                    </div>
                </div>

                <div class="field">
                    <label class="label email">Email</label>
                    <div class="control">
                        <input class="input" type="email" placeholder="{{'form_email' | translate}}" [(ngModel)]="email" required>
                    </div>
                </div>

                <div class="field">
                    <label class="label password">Password</label>
                    <div class="control">
                        <input class="input" type="password" placeholder="{{'form_pw' | translate}}" [(ngModel)]="password" *ngIf="!showPassword" required>
                        <input class="input" type="text" placeholder="{{'form_pw' | translate}}" [(ngModel)]="password" *ngIf="showPassword">
                        <button class="eye" *ngIf="!showPassword" (click)="showHidePassword()"><i class="fas fa-eye"></i></button>
                        <button class="eye" *ngIf="showPassword" (click)="showHidePassword()"><i class="fas fa-eye-slash"></i></button>
                    </div>
                </div>

                <div class="register-accept" >
                    <div class="register-conditions">

                        <p> <input type="checkbox" id="myCheckbox1" (click)="clickCheckbox1()" style="display: none;" />
                            <label for="myCheckbox1">
                                <img *ngIf="clickFirst == false" src="/assets/img/unchecked.png">
                                <img *ngIf="clickFirst == true" src="/assets/img/checked.png"> 
                            </label>
                            {{'first_condition_1' | translate}} <a href="https://s3-eu-west-1.amazonaws.com/com.elite.repository/contratti/app/myetraining/en_contract_v2.html"><u>{{'first_condition_2' | translate}}</u></a> {{'first_condition_3' | translate}} (*)
                        </p>
                    </div>
                </div>

                <div class="register-accept">
                    <div class="register-conditions second">
                        <p> <input type="checkbox" id="myCheckbox2" (click)="clickCheckbox2()" style="display: none;" />
                            <label for="myCheckbox2">
                                <img *ngIf="clickSecond == false" src="/assets/img/unchecked.png">
                                <img *ngIf="clickSecond == true" src="/assets/img/checked.png"> 
                            </label>
                            {{'first_condition_1' | translate}} <a href="https://s3-eu-west-1.amazonaws.com/com.elite.repository/contratti/app/myetraining/en_privacy_met_v2.html"><u>{{'second_condition' | translate}} </u> </a> (*) 
                        </p>
                    </div>
                </div>

                <div class="register-accept">
                    <div class="register-conditions second">
                        <p> <input type="checkbox" id="myCheckbox3" (click)="clickCheckbox3()" style="display: none;" />
                            <label for="myCheckbox3">
                                <img *ngIf="clickThird == false" src="/assets/img/unchecked.png">
                                <img *ngIf="clickThird == true" src="/assets/img/checked.png"> 
                            </label>
                            {{'third_condition' | translate}}
                        </p>
                        <div style="margin-top: 10px;"> (*) = {{'required_fields' | translate}}</div>
                      
                    </div>
                </div>
               
                <div class="field">
                    <div class="control submit">
                        
                        <button *ngIf="clickFirst == true && clickSecond == true" class="button is-primary is-fullwidth" (click) ="createAccount()">{{'create_account' | translate}}</button>
                        <button style="background-color: #E2001A;" disabled *ngIf="clickFirst == true && clickSecond == false" class="button is-primary is-fullwidth" (click) ="createAccount()">{{'create_account' | translate}}</button>
                        <button style="background-color: #E2001A;" disabled *ngIf="clickFirst == false && clickSecond == true" class="button is-primary is-fullwidth" (click) ="createAccount()">{{'create_account' | translate}}</button>
                        <button style="background-color: #E2001A;" disabled *ngIf="clickFirst == false && clickSecond == false" class="button is-primary is-fullwidth" (click) ="createAccount()">{{'create_account' | translate}}</button>
                    </div>
                </div>
            </div>

            <div class="container is-fluid login">
       
                {{'login_question' | translate}} <a (click) ="goToLoginPage()"> <strong >{{'login_access' | translate}}</strong></a>
            </div>
        </div>

    </div>
  <!--   <div class="column borraccia">
        <div class="section borraccia">
            <div class="container is-fluid">
            </div>
        </div>
    </div> -->
</div>