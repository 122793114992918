import { Component, OnInit } from '@angular/core';
import { EventService } from 'src/app/services/event.service';

@Component({
  selector: 'app-spinner',
  templateUrl: './spinner.component.html',
  styleUrls: ['./spinner.component.scss']
})
export class SpinnerComponent implements OnInit {

  spinnerVisible = false;
  text = 'Loading...';

  constructor(private eventService: EventService) {}

  public ngOnInit(): void {
    this.eventService.spinnerStatus.subscribe(state => {
        this.spinnerVisible = state;
    });
    this.eventService.spinnerText.subscribe(text => {
      this.text = text;
    });
  }

}
