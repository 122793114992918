
      <div class="section">
        <div class="container is-fluid">
          <a href="/home"><img class="logo" src="/assets/img/login_logo.png"></a>
          <div class="section center-box">
              <div class="image">
                <img class="logo" src="/assets/img/icon__double_check.png">
              </div>

              <div class="title">
                {{ 'feedback_title' | translate }}
              </div>
              <div class="subtitle">
                {{ order ? ('feedback_request_subtitle' | translate) : ('feedback_quotation_subtitle' | translate) }}
              </div>

              <div class="code">

                <p class="codeText">{{ 'feedback_code' | translate }} <span class="codeNumber">{{serial}} </span></p>
              </div>

              <div class=" submit">
                  <button class="button is-primary" (click) ="goBackHome()">{{'feedback_toHome' | translate}}</button>
              </div>


        </div>



        </div>

      </div>


