import { Component, OnInit, ViewChild, ElementRef, HostListener, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { UserDataService } from 'src/app/services/user-data.service';
import { EventService } from 'src/app/services/event.service';
import { ConfigurationService } from 'src/app/services/configuration.service';
import { environment } from 'src/environments/environment';
import { Utils } from 'src/app/services/utils';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-top-bar',
  templateUrl: './top-bar.component.html',
  styleUrls: ['./top-bar.component.scss']
})
export class TopBarComponent implements OnInit, OnDestroy {

  @ViewChild('menu') menuElement: ElementRef;

  public isMenuActive = false;
  public firstName = '';
  public lastName = '';
  public userId = 0;
  public imgUrl = '';
  // tslint:disable-next-line: variable-name
  public configs_number: number;
  public production = environment.production;
  private saveCompleteSub: Subscription;

  constructor(private eventService: EventService, private utils: Utils,
              private router: Router, private configsService: ConfigurationService,
              private userDataService: UserDataService,
              public translate: TranslateService) { }

  ngOnInit(): void {
    this.utils.refreshNumber.subscribe(num => {
      if (num === true) {
        this.configsService.getConfigurations().then(configs => {
          this.configs_number = configs.items.length;

        }).catch(error => {
          this.eventService.hideSpinner();
        });
      }
    });

    this.userDataService.checkUser().then(user => {
      this.firstName = user.firstName;
      this.lastName = user.lastName;
      this.imgUrl = 'https://s3-eu-west-1.amazonaws.com/' + environment.s3PublicBucket + '/' + user.uid + '/userimage.jpg';
      console.log(this.imgUrl);
      this.userId = user.id;
      this.configsService.getConfigurations().then(configs => {
      this.configs_number = configs.items.length;

    }).catch(error => {
      this.eventService.hideSpinner();
    });
    }).catch(error => {
      this.eventService.hideSpinner();
      this.firstName = '';
      this.lastName = '';
      this.userId = 0;
    });

    this.saveCompleteSub = this.eventService.getSaveComplete.subscribe(() => {
      this.router.navigate(['/login']);
    });
  }

  ngOnDestroy(): void {
    this.saveCompleteSub.unsubscribe();
  }

  profileClicked() {
    if (this.menuElement.nativeElement.classList.contains('is-active')) {
      this.isMenuActive = false;
    } else {
      this.isMenuActive = true;
    }
  }

  loginClicked() {
    if (this.router.url.startsWith('/configurator')) {
      this.eventService.save();
    } else {
      this.router.navigate(['/login']);
    }
  }

  registerClicked() {
    this.router.navigate(['/register']);
  }

  logoutClicked() {
    this.userDataService.logout();
    this.firstName = '';
    this.lastName = '';
    this.userId = 0;
    localStorage.setItem('token', null);
   /*  this.eventService.showSpinner(); */
    /* this.router.navigate(['/home']); */
    console.log(localStorage.getItem('token'));
  }

  @HostListener('document:click', ['$event'])
  clickout(event) {
    if (!this.menuElement.nativeElement.contains(event.target) && this.menuElement.nativeElement.classList.contains('is-active')) {
      this.isMenuActive = false;
    }
  }

  goToConfigurations() {
    this.eventService.showSpinner();
    this.router.navigate(['/configurations']);
  }

  changeLanguage(lang: string) {
    this.translate.use(lang);
  }
}
