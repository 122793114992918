import { Component, OnInit, ViewChild, Renderer2, ElementRef } from '@angular/core';
import { AreaInfo } from '../customization-area/customization-area.component';

@Component({
  selector: 'app-customization-area-text',
  templateUrl: './customization-area-text.component.html',
  styleUrls: ['./customization-area-text.component.scss']
})
export class CustomizationAreaTextComponent implements OnInit {

  @ViewChild('textElement') element: ElementRef;

  text = 'Text';

  constructor(private renderer: Renderer2) { }

  ngOnInit(): void {
  }

  public setPosition(x: number, y: number, width: number, height: number) {
    this.renderer.setStyle(this.element.nativeElement, 'top', y + 'px');
    this.renderer.setStyle(this.element.nativeElement, 'left', x + 'px');
  }

  public setInfo(info: AreaInfo) {
    this.renderer.setStyle(this.element.nativeElement, 'color', info.textColor);
    this.renderer.setStyle(this.element.nativeElement, 'font-family', info.fontFamily);
    this.renderer.setStyle(this.element.nativeElement, 'font-size', info.fontSizePixel + 'px');
    this.renderer.setStyle(this.element.nativeElement, 'line-height', info.fontSizePixel + 'px');

    if (!info.textBold) {
      this.renderer.setStyle(this.element.nativeElement, 'font-weight', 'inherit');
    } else {
      this.renderer.setStyle(this.element.nativeElement, 'font-weight', '700');
    }
    if (!info.textItalic) {
      this.renderer.setStyle(this.element.nativeElement, 'font-style', 'inherit');
    } else {
      this.renderer.setStyle(this.element.nativeElement, 'font-style', 'italic');
    }
    const decorations = [];
    if (info.textUnderline) {
      decorations.push('underline');
    }
    if (info.textStrikethrough) {
      decorations.push('line-through');
    }
    if (decorations.length === 0) {
      this.renderer.setStyle(this.element.nativeElement, 'text-decoration', 'inherit');
    } else {
      this.renderer.setStyle(this.element.nativeElement, 'text-decoration', decorations.join(' '));
    }
  }

}
