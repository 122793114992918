import { Component, OnInit, ViewChild, ViewContainerRef, ComponentFactoryResolver, Renderer2, ElementRef, AfterViewInit, Output, EventEmitter, NgZone, HostListener } from '@angular/core';
import { CustomizationAreaImageComponent } from '../customization-area-image/customization-area-image.component';
import { saveAs } from 'file-saver';
import { CustomizationAreaTextComponent } from '../customization-area-text/customization-area-text.component';

export class AreaInfo {
  type: string;
  componentRef: any;
  x: number;
  y: number;
  width: number;
  height: number;
  imgWidth: number;
  imgHeight: number;
  imgRatio: number;
  imgSrc: string;
  selected = false;

  text = '';
  horizontal = true;
  textBold = false;
  textItalic = false;
  textUnderline = false;
  textStrikethrough = false;
  textColor: string;
  fontSizePixel = 30;
  fontFamily = 'Open Sans';
}

@Component({
  selector: 'app-customization-area',
  templateUrl: './customization-area.component.html',
  styleUrls: ['./customization-area.component.scss']
})
export class CustomizationAreaComponent implements OnInit, AfterViewInit {

  @Output() changed = new EventEmitter<void>();

  @ViewChild('container', { read: ViewContainerRef }) entry: ViewContainerRef;
  @ViewChild('parent') parent: ElementRef;
  @ViewChild('topLeftHandle') topLeftHandle: ElementRef;
  @ViewChild('topRightHandle') topRightHandle: ElementRef;
  @ViewChild('bottomLeftHandle') bottomLeftHandle: ElementRef;
  @ViewChild('bottomRightHandle') bottomRightHandle: ElementRef;
  @ViewChild('textBox') textBoxElement: ElementRef;
  @ViewChild('textBoxInput') textBoxInputElement: ElementRef;
  @ViewChild('area') areaElement: ElementRef;

  areas: Array<AreaInfo> = [];
  padding = 20;
  dragging = false;
  draggedElement: ElementRef;
  mouseMoved = false;
  mouseX = 0;
  mouseY = 0;
  mouseArea = new AreaInfo();
  imageScale = 2;
  lastMouseDownTs: Date;
  text = '';
  textAreaSelected: AreaInfo;
  renderBackgroundColor = '#000000';
  color = '#000000';
  isMobile = false;
  width = 0;
  height = 0;
  textBoxHasFocus = false;

  fonts = [
    'Open Sans',
    'Roboto',
    'Lato',
    'Montserrat',
    'Oswald',
    'Raleway',
    'Arial',
    'Arial Black',
    'Verdana',
    'Tahoma',
    'Trebuchet MS',
    'Impact',
    'Times New Roman',
    'Didot',
    'Georgia',
    'American Typewriter',
    'Andalé Mono',
    'Courier',
    'Lucida Console',
    'Monaco',
    'Bradley Hand',
    'Brush Script MT',
    'Luminari',
    'Comic Sans MS'
  ];

  constructor(
    private resolver: ComponentFactoryResolver,
    private renderer: Renderer2,
    private ngZone: NgZone) {}

  ngOnInit(): void {
    this.fonts.sort();
  }

  ngAfterViewInit() {
  }

  setSize(width: number, height: number) {
    this.width = width;
    this.height = height;
    const w = this.areaElement.nativeElement.offsetWidth - 40;
    const h = (height / width) * w;
    this.renderer.setStyle(this.areaElement.nativeElement, 'height', (h + 40) + 'px');
  }

  refresh() {
    this.entry.clear();
    for (const element of this.areas.slice().reverse()) {
      if (element.type === 'image') {
        const factory = this.resolver.resolveComponentFactory(CustomizationAreaImageComponent);
        element.componentRef = this.entry.createComponent(factory);
      } else {
        const factory = this.resolver.resolveComponentFactory(CustomizationAreaTextComponent);
        element.componentRef = this.entry.createComponent(factory);
      }
    }

    setTimeout(() => {
      for (const element of this.areas) {
        this.resetPosition(element);
        if (element.type === 'image') {
          element.componentRef.instance.setImageBase64(element.imgSrc);
        } else {
          element.componentRef.instance.text = element.text;
          element.componentRef.instance.setInfo(element);
          if (!element.horizontal) {
            this.renderer.setStyle(element.componentRef.instance.element.nativeElement, 'transform', 'rotate(-90deg)');
          }
        }
      }
    }, 0);
  }

  addImage(src: string, width: number, height: number) {
    const info = new AreaInfo();
    info.type = 'image';

    const parentRatio = this.parent.nativeElement.offsetWidth / this.parent.nativeElement.offsetHeight;
    const imgRatio = width / height;
    info.imgWidth = width;
    info.imgHeight = height;
    info.imgRatio = imgRatio;
    info.imgSrc = src;
    if (parentRatio > imgRatio) {
      info.y = 0;
      info.height = this.parent.nativeElement.offsetHeight;
      info.width = this.parent.nativeElement.offsetHeight * imgRatio;
      info.x = (this.parent.nativeElement.offsetWidth - info.width) / 2;
    } else {
      info.x = 0;
      info.width = this.parent.nativeElement.offsetWidth;
      info.height = this.parent.nativeElement.offsetWidth / imgRatio;
      info.y = (this.parent.nativeElement.offsetHeight - info.height) / 2;
    }

    const factory = this.resolver.resolveComponentFactory(CustomizationAreaImageComponent);
    info.componentRef = this.entry.createComponent(factory);
    this.areas.unshift(info);

    setTimeout(() => {
      this.resetPosition(info);
      info.componentRef.instance.setImageBase64(src);
      this.changed.emit();
    }, 0);
  }

  addText() {
    const info = new AreaInfo();
    info.type = 'text';
    info.x = 0;
    info.y = 0;
    info.text = 'Text';
    info.textColor = '#000000';
    const factory = this.resolver.resolveComponentFactory(CustomizationAreaTextComponent);
    info.componentRef = this.entry.createComponent(factory);
    this.areas.unshift(info);
    this.changed.emit();
    setTimeout(() => {
      info.componentRef.instance.setInfo(info);
      this.changed.emit();
    }, 0);
  }

  onTextBoxEnter() {
    this.renderer.setStyle(this.textBoxElement.nativeElement, 'visibility', 'hidden');
    this.textAreaSelected.text = this.text;
    this.textAreaSelected.componentRef.instance.text = this.text;
    this.textAreaSelected.componentRef.instance.setInfo(this.textAreaSelected);
    this.textAreaSelected = null;
    this.changed.emit();
    this.areaElement.nativeElement.focus();
  }

  remove() {
    this.areas.forEach((area, index) => {
      if (area.selected) {
        this.entry.remove(this.areas.length - index - 1);
        this.renderer.setStyle(this.topLeftHandle.nativeElement, 'visibility', 'hidden');
        this.renderer.setStyle(this.topRightHandle.nativeElement, 'visibility', 'hidden');
        this.renderer.setStyle(this.bottomLeftHandle.nativeElement, 'visibility', 'hidden');
        this.renderer.setStyle(this.bottomRightHandle.nativeElement, 'visibility', 'hidden');
        this.areas.splice(index, 1);
      }
    });
    this.changed.emit();
  }

  moveToTop() {
    let areaIndex = -1;
    this.areas.forEach((element, index) => {
      if (element.selected) {
        areaIndex = index;
      }
    });
    if (areaIndex === -1 || areaIndex === 0) {
      return;
    }
    const area = this.areas[areaIndex];
    this.areas.splice(areaIndex, 1);
    this.areas.unshift(area);
    this.entry.clear();
    for (const element of this.areas.slice().reverse()) {
      if (element.type === 'image') {
        const factory = this.resolver.resolveComponentFactory(CustomizationAreaImageComponent);
        element.componentRef = this.entry.createComponent(factory);
      } else {
        const factory = this.resolver.resolveComponentFactory(CustomizationAreaTextComponent);
        element.componentRef = this.entry.createComponent(factory);
      }
    }

    setTimeout(() => {
      for (const element of this.areas) {
        this.resetPosition(element);
        if (element.type === 'image') {
          element.componentRef.instance.setImageBase64(element.imgSrc);
        } else {
          element.componentRef.instance.text = element.text;
          element.componentRef.instance.setInfo(element);
        }
      }
      this.changed.emit();
    }, 0);
  }

  moveToBottom() {
    let areaIndex = -1;
    this.areas.forEach((element, index) => {
      if (element.selected) {
        areaIndex = index;
      }
    });
    if (areaIndex === -1 || areaIndex === this.areas.length - 1) {
      return;
    }
    const area = this.areas[areaIndex];
    this.areas.splice(areaIndex, 1);
    this.areas.push(area);
    this.entry.clear();
    for (const element of this.areas.slice().reverse()) {
      if (element.type === 'image') {
        const factory = this.resolver.resolveComponentFactory(CustomizationAreaImageComponent);
        element.componentRef = this.entry.createComponent(factory);
      } else {
        const factory = this.resolver.resolveComponentFactory(CustomizationAreaTextComponent);
        element.componentRef = this.entry.createComponent(factory);
      }
    }

    setTimeout(() => {
      for (const element of this.areas) {
        this.resetPosition(element);
        if (element.type === 'image') {
          element.componentRef.instance.setImageBase64(element.imgSrc);
        } else {
          element.componentRef.instance.text = element.text;
          element.componentRef.instance.setInfo(element);
        }
      }
      this.changed.emit();
    }, 0);
  }

  resetPosition(area: AreaInfo) {
    area.componentRef.instance.setPosition(area.x, area.y, area.width, area.height);
    if (area.selected && area.type === 'image') {
      this.renderer.setStyle(this.topLeftHandle.nativeElement, 'visibility', 'visible');
      this.renderer.setStyle(this.topLeftHandle.nativeElement, 'top', (area.y + this.padding) + 'px');
      this.renderer.setStyle(this.topLeftHandle.nativeElement, 'left', (area.x + this.padding) + 'px');

      this.renderer.setStyle(this.topRightHandle.nativeElement, 'visibility', 'visible');
      this.renderer.setStyle(this.topRightHandle.nativeElement, 'top', (area.y + this.padding) + 'px');
      this.renderer.setStyle(this.topRightHandle.nativeElement, 'left', (area.x + area.width - 8 + this.padding) + 'px');

      this.renderer.setStyle(this.bottomLeftHandle.nativeElement, 'visibility', 'visible');
      this.renderer.setStyle(this.bottomLeftHandle.nativeElement, 'top', (area.y + area.height - 8 + this.padding) + 'px');
      this.renderer.setStyle(this.bottomLeftHandle.nativeElement, 'left', (area.x + this.padding) + 'px');

      this.renderer.setStyle(this.bottomRightHandle.nativeElement, 'visibility', 'visible');
      this.renderer.setStyle(this.bottomRightHandle.nativeElement, 'top', (area.y + area.height - 8 + this.padding) + 'px');
      this.renderer.setStyle(this.bottomRightHandle.nativeElement, 'left', (area.x + area.width - 8 + this.padding) + 'px');
    } else if (area.selected && area.type === 'text') {
      this.renderer.setStyle(area.componentRef.instance.element.nativeElement, 'background-color', 'rgba(0, 0, 0, 0.125)');
    } else if (!area.selected && area.type === 'text') {
      this.renderer.setStyle(area.componentRef.instance.element.nativeElement, 'background-color', 'inherit');
    } else {
      this.renderer.setStyle(this.topLeftHandle.nativeElement, 'visibility', 'hidden');
      this.renderer.setStyle(this.topRightHandle.nativeElement, 'visibility', 'hidden');
      this.renderer.setStyle(this.bottomLeftHandle.nativeElement, 'visibility', 'hidden');
      this.renderer.setStyle(this.bottomRightHandle.nativeElement, 'visibility', 'hidden');
    }
  }

  onMouseDown(event: MouseEvent) {
    if (this.isMobile) {
      return;
    }

    if (this.textAreaSelected) {
      return;
    }

    if (this.lastMouseDownTs) {
      const ts = this.lastMouseDownTs.getTime();
      const nowTs = (new Date()).getTime();
      if (nowTs - ts < 200) {
        for (const area of this.areas) {
          if (area.selected && area.type === 'text') {
            this.textAreaSelected = area;
            // this.renderer.setStyle(this.textBoxElement.nativeElement, 'top', (area.y + this.padding) + 'px');
            // this.renderer.setStyle(this.textBoxElement.nativeElement, 'left', (area.x + this.padding) + 'px');
            this.renderer.setStyle(this.textBoxElement.nativeElement, 'visibility', 'visible');
            this.text = area.componentRef.instance.text;
            this.color = area.textColor;
            this.refreshTextBoxInputElement();

            setTimeout(() => {
              this.textBoxInputElement.nativeElement.select();
            }, 0);
          }
        }
        return;
      }
    }

    this.lastMouseDownTs = new Date();
    this.mouseX = event.screenX;
    this.mouseY = event.screenY;
    this.mouseMoved = false;
    if (this.checkRect(event.clientX, event.clientY, this.topLeftHandle)) {
      this.dragging = true;
      this.draggedElement = this.topLeftHandle;
      this.populateMouseArea();
    } else if (this.checkRect(event.clientX, event.clientY, this.topRightHandle)) {
      this.dragging = true;
      this.draggedElement = this.topRightHandle;
      this.populateMouseArea();
    } else if (this.checkRect(event.clientX, event.clientY, this.bottomLeftHandle)) {
      this.dragging = true;
      this.draggedElement = this.bottomLeftHandle;
      this.populateMouseArea();
    } else if (this.checkRect(event.clientX, event.clientY, this.bottomRightHandle)) {
      this.dragging = true;
      this.draggedElement = this.bottomRightHandle;
      this.populateMouseArea();
    } else {
      for (const area of this.areas) {
        if (area.selected) {
          area.selected = false;
          this.resetPosition(area);
        }
      }
      for (const area of this.areas) {
        if (area.componentRef && this.checkRect(event.clientX, event.clientY, area.componentRef.instance.element)) {
          this.dragging = true;
          this.draggedElement = area.componentRef.instance.element;
          area.selected = true;
          this.resetPosition(area);
          break;
        }
      }
    }
  }

  private populateMouseArea() {
    const area = this.getSelectedArea();
    if (!area) {
      return;
    }
    this.mouseArea.x = area.x;
    this.mouseArea.y = area.y;
    this.mouseArea.width = area.width;
    this.mouseArea.height = area.height;
  }

  private getSelectedArea(): AreaInfo {
    const filtered = this.areas.filter((a: AreaInfo) => {
      return a.selected;
    });
    if (filtered.length === 0) {
      return null;
    }
    return filtered[0];
  }

  onTouchStart(event: TouchEvent) {
    this.isMobile = true;

    if (this.textAreaSelected) {
      return;
    }

    if (this.lastMouseDownTs) {
      const ts = this.lastMouseDownTs.getTime();
      const nowTs = (new Date()).getTime();
      if (nowTs - ts < 200) {
        for (const area of this.areas) {
          if (area.selected && area.type === 'text') {
            this.textAreaSelected = area;
            // this.renderer.setStyle(this.textBoxElement.nativeElement, 'top', (area.y + this.padding) + 'px');
            // this.renderer.setStyle(this.textBoxElement.nativeElement, 'left', (area.x + this.padding) + 'px');
            this.renderer.setStyle(this.textBoxElement.nativeElement, 'visibility', 'visible');
            this.text = area.componentRef.instance.text;
            this.color = area.textColor;
            this.refreshTextBoxInputElement();

            setTimeout(() => {
              this.textBoxInputElement.nativeElement.select();
            }, 0);
          }
        }
        return;
      }
    }

    this.lastMouseDownTs = new Date();
    this.mouseX = event.changedTouches[0].screenX;
    this.mouseY = event.changedTouches[0].screenY;
    this.mouseMoved = false;
    if (this.checkRect(event.changedTouches[0].clientX, event.changedTouches[0].clientY, this.topLeftHandle)) {
      this.dragging = true;
      this.draggedElement = this.topLeftHandle;
      this.populateMouseArea();
    } else if (this.checkRect(event.changedTouches[0].clientX, event.changedTouches[0].clientY, this.topRightHandle)) {
      this.dragging = true;
      this.draggedElement = this.topRightHandle;
      this.populateMouseArea();
    } else if (this.checkRect(event.changedTouches[0].clientX, event.changedTouches[0].clientY, this.bottomLeftHandle)) {
      this.dragging = true;
      this.draggedElement = this.bottomLeftHandle;
      this.populateMouseArea();
    } else if (this.checkRect(event.changedTouches[0].clientX, event.changedTouches[0].clientY, this.bottomRightHandle)) {
      this.dragging = true;
      this.draggedElement = this.bottomRightHandle;
      this.populateMouseArea();
    } else {
      for (const area of this.areas) {
        if (area.selected) {
          area.selected = false;
          this.resetPosition(area);
        }
      }
      for (const area of this.areas) {
        if (area.componentRef &&
          this.checkRect(event.changedTouches[0].clientX, event.changedTouches[0].clientY, area.componentRef.instance.element)) {

          this.dragging = true;
          this.draggedElement = area.componentRef.instance.element;
          area.selected = true;
          this.resetPosition(area);
          break;
        }
      }
    }
  }

  checkRect(clientX: number, clientY: number, element: ElementRef): boolean {
    const rect = element.nativeElement.getBoundingClientRect();
    if (clientX < rect.left
      || clientX > (rect.left + rect.width)) {
      return false;
    }

    if (clientY < rect.top
      || clientY > (rect.top + rect.height)) {
      return false;
    }

    return true;
  }

  onMouseMove(event: MouseEvent) {
    if (!this.dragging || this.isMobile) {
      return;
    }
    const area = this.getSelectedArea();
    if (!area) {
      return;
    }
    this.mouseMoved = true;
    if (!this.dragging) {
      return;
    }

    const y = event.screenY;
    const x = event.screenX;
    
    this.moveArea(area, x, y);
  }

  onTouchMove(event: TouchEvent) {
    event.preventDefault();

    if (!this.dragging) {
      return;
    }
    const area = this.getSelectedArea();
    if (!area) {
      return;
    }
    this.mouseMoved = true;
    if (!this.dragging) {
      return;
    }

    const y = event.changedTouches[0].screenY;
    const x = event.changedTouches[0].screenX;

    this.moveArea(area, x, y);
  }

  private moveArea(area: AreaInfo, x: number, y: number) {
    let deltaY = y - this.mouseY;
    let deltaX = x - this.mouseX;

    if (this.draggedElement === area.componentRef.instance.element) {
      area.x += deltaX;
      area.y += deltaY;
      this.resetPosition(area);
      this.mouseX = x;
      this.mouseY = y;
      return;
    }

    if (this.draggedElement === this.topLeftHandle) {
      this.mouseArea.x += deltaX;
      this.mouseArea.y += deltaY;
      this.mouseArea.width -= deltaX;
      this.mouseArea.height -= deltaY;
    } else if (this.draggedElement === this.topRightHandle) {
      this.mouseArea.y += deltaY;
      this.mouseArea.width += deltaX;
      this.mouseArea.height -= deltaY;
    } else if (this.draggedElement === this.bottomLeftHandle) {
      this.mouseArea.x += deltaX;
      this.mouseArea.width -= deltaX;
      this.mouseArea.height += deltaY;
    } else if (this.draggedElement === this.bottomRightHandle) {
      this.mouseArea.width += deltaX;
      this.mouseArea.height += deltaY;
    }

    area.x = this.mouseArea.x;
    area.y = this.mouseArea.y;
    area.width = this.mouseArea.width;
    area.height = this.mouseArea.height;

    if (area.width < 16) {
      if (this.draggedElement === this.topLeftHandle || this.draggedElement === this.bottomLeftHandle) {
        area.x -= 16 - area.width
      }
      area.width = 16;
    }

    if (area.height < 16) {
      if (this.draggedElement === this.topLeftHandle || this.draggedElement === this.topRightHandle) {
        area.y -= 16 - area.height
      }
      area.height = 16;
    }

    this.resetPosition(area);
    this.mouseX = x;
    this.mouseY = y;
  }

  onMouseUp(event: MouseEvent) {
    if (this.mouseMoved) {
      this.mouseMoved = false;
      this.changed.emit();
    }
    this.dragging = false;
    this.draggedElement = null;
  }

  onMouseLeave(event: MouseEvent) {
    if (this.mouseMoved) {
      this.mouseMoved = false;
      this.changed.emit();
    }
    this.dragging = false;
    this.draggedElement = null;
  }

  onKeyDown(event: KeyboardEvent) {
    if (this.textBoxHasFocus) {
      return;
    }
    event.preventDefault();
    const area = this.getSelectedArea();
    if (area && event.key == 'Delete') {
      this.remove();
    }
  }

  public onFocus(event: any): void {
    this.textBoxHasFocus = true;
  }

  public onBlur(event: any): void {
    this.textBoxHasFocus = false;
  }

  onFileDrop(files: FileList) {
    if (files.length > 0) {
      console.log(files[0].type);
      if (files[0].type === 'image/jpeg' || files[0].type === 'image/png') {
        const reader = new FileReader();
        reader.readAsDataURL(files[0]);
        reader.onload = () => {
          const base64 = reader.result as string;
          const URL = window.URL || window.webkitURL;
          const img = new Image();
          img.src = URL.createObjectURL(files[0]);
          img.onload = (e: any) => {
            this.addImage(base64, e.target.width, e.target.height);
          };
        };
      } else if (files[0].type === 'application/pdf') {
        this.loadPdf(files[0]);
      }
    }
  }

  fileSelected(event: any) {
    const file = event.target.files[0];
    if (file.type === 'image/jpeg' || file.type === 'image/png') {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        const base64 = reader.result as string;
        const URL = window.URL || window.webkitURL;
        const img = new Image();
        img.src = URL.createObjectURL(file);
        img.onload = (e: any) => {
          if (e.path) {
            this.addImage(base64, e.path[0].width, e.path[0].height);
          } else if (e.srcElement) {
            this.addImage(base64, e.srcElement.width, e.srcElement.height);
          }
        };
      };
    } else if (file.type == 'application/pdf') {
      this.loadPdf(file);
    }
  }

  private loadPdf(file: File) {
    var THIS = this;
    var PDFJS = window['pdfjs-dist/build/pdf'];
    PDFJS.GlobalWorkerOptions.workerSrc = '/assets/js/pdf.worker.js';

    const reader = new FileReader();
    reader.onload = function() {
      const data = reader.result as Uint8Array;
      var typedarray = new Uint8Array(data);
      PDFJS.getDocument(typedarray).promise.then((pdf: any) => {
        pdf.getPage(1).then((page: any) => {
          var viewport = page.getViewport({scale: 300 / 72});
          const canvas = document.createElement('canvas');
          var context = canvas.getContext('2d');
          canvas.height = viewport.height;
          canvas.width = viewport.width;
          
          page.render({canvasContext: context, viewport: viewport}).promise.then((pippo: any) => {
            canvas.toBlob((blob) => {
              const reader = new FileReader();
              reader.readAsDataURL(blob);
              reader.onloadend = () => {
                const base64 = reader.result as string;
                THIS.ngZone.run(() => {
                  THIS.addImage(base64, viewport.width, viewport.height);
                });
              };
            });
          }); // page render end

        }); // getPage end
      }); // getDocument end
    };
    reader.readAsArrayBuffer(file);
  }

  test() {
    this.renderImageInternal(true, 300, (blob) => {
      saveAs(blob, 'test_image.png');
    });
  }

  renderImage(drawBackground: boolean, dpi: number, callback: (blob: Blob | null) => void) {
    this.renderImageInternal(drawBackground, dpi, callback);
  }

  private renderImageInternal(drawBackground: boolean, dpi: number, callback: (blob: Blob | null) => void) {
    console.log(dpi);
    const width = this.parent.nativeElement.offsetWidth;
    const height = this.parent.nativeElement.offsetHeight;
    const canvas = document.createElement('canvas');
    this.imageScale = ((this.width / 25.4) * dpi) / width;
    canvas.width = width * this.imageScale;
    canvas.height = height * this.imageScale;

    const ctx = canvas.getContext('2d');
    if (drawBackground) {
      ctx.fillStyle = this.renderBackgroundColor;
      ctx.fillRect(0, 0, canvas.width, canvas.height);
    }

    this.drawAreaAtIndex(this.areas.length - 1, canvas, callback);
  }

  drawAreaAtIndex(index: number, canvas: HTMLCanvasElement, callback: (blob: Blob | null) => void) {
    if (index < 0) {
      if (canvas.toBlob) {
        canvas.toBlob((blob) => {
          callback(blob);
        });
      } else {
        console.log('toBlob not supported!!');
      }
      return;
    }

    const area = this.areas[index];
    if (area.type === 'image') {
      const context = canvas.getContext('2d');
      const img = new Image();
      img.onload = () => {
        const imgAspect = this.areas[index].imgWidth / this.areas[index].imgHeight;
        const areaAspect = this.areas[index].width / this.areas[index].height;
        let offsetX = 0;
        let offsetY = 0;
        let width = this.areas[index].width;
        let height = this.areas[index].height;
        if (imgAspect > areaAspect) {
          // vertical offset
          height = this.areas[index].width / imgAspect;
          offsetY = (this.areas[index].height - height) / 2
        } else {
          // horizontal offset
          width = this.areas[index].height * imgAspect;
          offsetX = (this.areas[index].width - width) / 2
        }
        context.drawImage(img, 0, 0,
          this.areas[index].imgWidth, this.areas[index].imgHeight,
          (this.areas[index].x + offsetX) * this.imageScale, (this.areas[index].y + offsetY) * this.imageScale,
          width * this.imageScale, height * this.imageScale);
        this.drawAreaAtIndex(index - 1, canvas, callback);
      };
      img.src = this.areas[index].imgSrc;
    } else {
      const context = canvas.getContext('2d');
      if (!area.componentRef.instance.element) {
        this.drawAreaAtIndex(index - 1, canvas, callback);
        return;
      }
      const tw = area.componentRef.instance.element.nativeElement.offsetWidth * this.imageScale;
      const th = area.componentRef.instance.element.nativeElement.offsetHeight * this.imageScale;

      context.fillStyle = area.textColor;
      context.textBaseline = 'top';
      const fontStyle = [];
      if (area.textItalic) {
        fontStyle.push('italic');
      }
      if (area.textBold) {
        fontStyle.push('700');
      }
      fontStyle.push((area.fontSizePixel * this.imageScale) + 'px ' + area.fontFamily);
      context.font = fontStyle.join(' ');

      let x = area.x * this.imageScale;
      let y = area.y * this.imageScale;
      if (!area.horizontal) {
        x = - (tw / 2) - (th / 2) - area.y * this.imageScale;
        y = (tw / 2) - (th / 2) + area.x * this.imageScale;
      }

      if (area.horizontal) {
        context.fillText(area.componentRef.instance.text, x, y);
      } else {
        context.rotate(-Math.PI / 2);
        context.fillText(area.componentRef.instance.text, x, y);
        context.rotate(Math.PI / 2);
      }

      const width = context.measureText(area.componentRef.instance.text).width;
      const height = 60 * 0.90;
      if (area.textUnderline) {
        if (!area.horizontal) {
          context.rotate(-Math.PI / 2);
        }
        context.beginPath();
        context.strokeStyle = area.textColor;
        context.lineWidth = 3 * this.imageScale;
        context.moveTo(x, y + height);
        context.lineTo(x + width, y + height);
        context.stroke();
        if (!area.horizontal) {
          context.rotate(Math.PI / 2);
        }
      }
      if (area.textStrikethrough) {
        if (!area.horizontal) {
          context.rotate(-Math.PI / 2);
        }
        context.beginPath();
        context.strokeStyle = area.textColor;
        context.lineWidth = 3 * this.imageScale;
        context.moveTo(x, y + height * 0.55);
        context.lineTo(x + width, y + height * 0.55);
        context.stroke();
        if (!area.horizontal) {
          context.rotate(Math.PI / 2);
        }
      }

      this.drawAreaAtIndex(index - 1, canvas, callback);
    }
  }

  textChanged() {
    this.refreshTextBoxInputElement();
  }

  boldPressed() {
    this.textAreaSelected.textBold = !this.textAreaSelected.textBold;
    this.refreshTextBoxInputElement();
  }

  italicPressed() {
    this.textAreaSelected.textItalic = !this.textAreaSelected.textItalic;
    this.refreshTextBoxInputElement();
  }

  underlinePressed() {
    this.textAreaSelected.textUnderline = !this.textAreaSelected.textUnderline;
    this.refreshTextBoxInputElement();
  }

  strikethroughPressed() {
    this.textAreaSelected.textStrikethrough = !this.textAreaSelected.textStrikethrough;
    this.refreshTextBoxInputElement();
  }

  textColorChanged() {
    this.textAreaSelected.textColor = this.color;
    this.refreshTextBoxInputElement();
  }

  horizontalPressed() {
    this.textAreaSelected.horizontal = true;
    this.refreshTextBoxInputElement();
  }

  verticalPressed() {
    this.textAreaSelected.horizontal = false;
    this.refreshTextBoxInputElement();
  }

  decreaseFontSize() {
    if (this.textAreaSelected.fontSizePixel > 12) {
      this.textAreaSelected.fontSizePixel -= 2;
      this.refreshTextBoxInputElement();
    }
  }

  increaseFontSize() {
    if (this.textAreaSelected.fontSizePixel < 200) {
      this.textAreaSelected.fontSizePixel += 2;
      this.refreshTextBoxInputElement();
    }
  }

  fontChanged() {
    this.refreshTextBoxInputElement();
  }

  fontSizeChanged() {
    if (this.textAreaSelected.fontSizePixel > 200) {
      this.textAreaSelected.fontSizePixel = 200;
    } else if (this.textAreaSelected.fontSizePixel < 12) {
      this.textAreaSelected.fontSizePixel = 12;
    }
    this.refreshTextBoxInputElement();
  }

  refreshTextBoxInputElement() {
    this.renderer.setStyle(this.textBoxInputElement.nativeElement, 'color', this.color);
    this.renderer.setStyle(this.textBoxInputElement.nativeElement, 'font-family', this.textAreaSelected.fontFamily);
    this.renderer.setStyle(this.textBoxInputElement.nativeElement, 'font-size', this.textAreaSelected.fontSizePixel + 'px');
    this.renderer.setStyle(this.textBoxInputElement.nativeElement, 'line-height', this.textAreaSelected.fontSizePixel + 'px');

    if (!this.textAreaSelected.textBold) {
      this.renderer.setStyle(this.textBoxInputElement.nativeElement, 'font-weight', 'inherit');
    } else {
      this.renderer.setStyle(this.textBoxInputElement.nativeElement, 'font-weight', '700');
    }

    if (!this.textAreaSelected.textItalic) {
      this.renderer.setStyle(this.textBoxInputElement.nativeElement, 'font-style', 'inherit');
    } else {
      this.renderer.setStyle(this.textBoxInputElement.nativeElement, 'font-style', 'italic');
    }

    const decorations = [];
    if (this.textAreaSelected.textUnderline) {
      decorations.push('underline');
    }
    if (this.textAreaSelected.textStrikethrough) {
      decorations.push('line-through');
    }
    if (decorations.length === 0) {
      this.renderer.setStyle(this.textBoxInputElement.nativeElement, 'text-decoration', 'inherit');
    } else {
      this.renderer.setStyle(this.textBoxInputElement.nativeElement, 'text-decoration', decorations.join(' '));
    }

    this.textAreaSelected.text = this.text;
    this.textAreaSelected.componentRef.instance.text = this.text;
    this.textAreaSelected.componentRef.instance.setInfo(this.textAreaSelected);

    if (this.textAreaSelected.horizontal) {
      this.renderer.setStyle(this.textAreaSelected.componentRef.instance.element.nativeElement, 'transform', 'inherit');
    } else {
      this.renderer.setStyle(this.textAreaSelected.componentRef.instance.element.nativeElement, 'transform', 'rotate(-90deg)');
    }
  }

}
