import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { EngineService } from '../services/engine.service';

@Component({
  selector: 'app-engine',
  templateUrl: './engine.component.html',
  styleUrls: ['./engine.component.scss']
})
export class EngineComponent implements OnInit {

  @ViewChild('rendererCanvas', { static: true })
  public rendererCanvas: ElementRef<HTMLCanvasElement>;

  public constructor(private engServ: EngineService) { }

  public ngOnInit(): void {
    this.engServ.createScene(this.rendererCanvas);
  }

  public setWaterBottleObj(url: string, capYOffset: number) {
    this.engServ.setWaterBottleObj(url, capYOffset);
  }

  public setCapObj(url: string) {
    this.engServ.setCapObj(url);
  }

  public setValveObj(url: string) {
    this.engServ.setValveObj(url);
  }
}
