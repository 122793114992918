<div class="login-borraccia">
  <div class="login-borraccia-top">
  </div>
</div>

<div class="columns is-gapless">
  <div class="column is-5">

    <div class="section">
      <div class="container is-fluid">
        <a href="/home"><img class="logo" src="/assets/img/login_logo.png"></a>
      </div>
      <div class="container is-fluid login">
        {{ 'password_recover_title' | translate }} <img src="/assets/img/red_dot.png">
      </div>
      <div class="container is-fluid login-subtitle">
        {{ 'password_recover_subtitle' | translate }}
      </div>

      <div class="container is-fluid login-form">
        <div class="field">
          <label class="label">Email</label>
          <div class="control">
            <input class="input" type="email" [(ngModel)]="email">
          </div>
        </div>

        <div class="field">
          <div class="error" *ngIf="error">{{ 'error_wrong_email' | translate }}</div>
        </div>

        <div class="field">
          <div class="control submit">
            <button class="button is-primary is-fullwidth" (click)="recover()">{{'recover' | translate}}</button>
          </div>
        </div>
      </div>

    </div>

  </div>
</div>