import { Injectable, NgZone, ElementRef } from '@angular/core';
import {
  Engine,
  FreeCamera,
  Scene,
  Light,
  Mesh,
  Color4,
  Vector3,
  HemisphericLight,
  StandardMaterial,
  Texture,
  SceneLoader,
  Space,
  AbstractMesh,
  MeshBuilder,
  Tools,
  DefaultRenderingPipeline
} from 'babylonjs';
import 'babylonjs-materials';
import 'babylonjs-loaders';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class EngineService {
  private canvas: HTMLCanvasElement;
  private engine: Engine;
  private camera: FreeCamera;
  private scene: Scene;

  private sphere: Mesh;
  private ringMesh: AbstractMesh;

  private bodyMeshes: AbstractMesh[] = [];

  private dragging = false;
  private startX = 0;
  private startY = 0;
  private rotation = 0;
  private startRotation = 0;

  private draggingCamera = false;
  private lastCameraY = 0;

  private bottleMaterial: StandardMaterial;
  private capMeshes: AbstractMesh[] = [];
  private capMaterial: StandardMaterial;
  private valveMeshes: AbstractMesh[] = [];
  private valveMaterial: StandardMaterial;

  private cameraAngle = Math.PI / 8;
  private cameraRadius = 0;
  private cameraPointY = 0;
  private capYOffset = 0;
  private bottleColor = '';

  private readyEvent = new BehaviorSubject<boolean>(false);

  public constructor(
    private ngZone: NgZone
  ) {}

  public createScene(canvas: ElementRef<HTMLCanvasElement>): void {
    // The first step is to get the reference of the canvas element from our HTML document
    this.canvas = canvas.nativeElement;

    // Then, load the Babylon 3D engine:
    this.engine = new Engine(this.canvas,  true);
    this.engine.setHardwareScalingLevel(1 / window.devicePixelRatio);

    // create a basic BJS Scene object
    this.scene = new Scene(this.engine);
    this.scene.clearColor = new Color4(0, 0, 0, 0);

    // create a FreeCamera, and set its position to (x:5, y:10, z:-20 )
    this.camera = new FreeCamera('camera1', new Vector3(0, 12, -20), this.scene);

    // target the camera to scene origin
    // this.camera.setTarget(Vector3.Zero());
    this.camera.setTarget(new Vector3(0, 7, 0));

    // attach the camera to the canvas
    // this.camera.attachControl(this.canvas, false);

    // create a basic light, aiming 0,1,0 - meaning, to the sky
    new HemisphericLight('light1', new Vector3(1, 1, 0), this.scene);
    new HemisphericLight('light1', new Vector3(-1, 0, 1), this.scene);

    this.scene.onPointerObservable.add((pointerInfo) => {
      // if (pointerInfo.event.altKey && !this.dragging) {
      //   switch (pointerInfo.type) {
      //     case BABYLON.PointerEventTypes.POINTERDOWN:
      //       this.draggingCamera = true;
      //       this.lastCameraY = pointerInfo.event.clientY;
      //       break;
      //     case BABYLON.PointerEventTypes.POINTERUP:
      //       this.draggingCamera = false;
      //       break;
      //     case BABYLON.PointerEventTypes.POINTERMOVE:
      //       if (this.draggingCamera) {

      //       }
      //       break;
      //     default:
      //       break;
      //   }
      //   return;
      // } else if (!this.draggingCamera) {
        switch (pointerInfo.type) {
          case BABYLON.PointerEventTypes.POINTERDOWN:
            if (pointerInfo.pickInfo.hit) {
              this.dragging = true;
              this.startX = pointerInfo.event.clientX;
              this.startY = pointerInfo.event.clientY;
              this.startRotation = this.rotation;
            }
            break;
          case BABYLON.PointerEventTypes.POINTERUP:
            this.dragging = false;
            break;
          case BABYLON.PointerEventTypes.POINTERMOVE:
            if (this.dragging) {
              const dx = pointerInfo.event.clientX - this.startX;
              // this.rotation = this.startRotation - (dx / 20);
              // for (const mesh of this.bodyMeshes) {
              //   mesh.rotation = new Vector3(0, this.rotation, 0);
              // }
              // this.capMesh.rotation = new Vector3(0, this.rotation, 0);
              // this.valveMesh.rotation = new Vector3(0, this.rotation, 0);
              for (const mesh of this.bodyMeshes) {
                mesh.rotate(new Vector3(0, 1, 0), -dx / 100, BABYLON.Space.LOCAL);
              }
              for (const mesh of this.capMeshes) {
                mesh.rotate(new Vector3(0, 1, 0), -dx / 100, BABYLON.Space.LOCAL);
              }
              for (const mesh of this.valveMeshes) {
                mesh.rotate(new Vector3(0, 1, 0), -dx / 100, BABYLON.Space.LOCAL);
              }
              this.startX = pointerInfo.event.clientX;

              const dy = pointerInfo.event.clientY - this.startY;
              this.startY = pointerInfo.event.clientY;
              this.cameraAngle = this.cameraAngle + (dy / 200);
              if (this.cameraAngle > Math.PI / 4) {
                this.cameraAngle = Math.PI / 4;
              } else if (this.cameraAngle < -Math.PI / 4) {
                this.cameraAngle = -Math.PI / 4;
              }
              const x = Math.cos(this.cameraAngle) * this.cameraRadius;
              const y = Math.sin(this.cameraAngle) * this.cameraRadius;
              this.camera.position = new Vector3(0, this.cameraPointY + y, -x);
              this.camera.setTarget(new Vector3(0, this.cameraPointY, 0));

              // this.camera.position = new Vector3(0, minMax.max.y, -minMax.max.y * 1.666);
              // this.camera.setTarget(new Vector3(0, 7, 0));
            }
            break;
          default:
            break;
        }
      // }
    });

    this.bottleMaterial = new StandardMaterial('cap_material', this.scene);
    this.bottleMaterial.diffuseColor = new BABYLON.Color3(1, 1, 1);

    this.capMaterial = new StandardMaterial('cap_material', this.scene);
    this.capMaterial.diffuseColor = new BABYLON.Color3(1, 1, 1);
    this.capMaterial.specularColor = new BABYLON.Color3(0, 0, 0);

    this.valveMaterial = new StandardMaterial('valve_material', this.scene);
    this.valveMaterial.diffuseColor = new BABYLON.Color3(1, 1, 1);
    this.valveMaterial.specularColor = new BABYLON.Color3(0, 0, 0);

    const defaultPipeline = new DefaultRenderingPipeline(
      'DefaultRenderingPipeline',
      true, // is HDR?
      this.scene,
      this.scene.cameras
    );
    defaultPipeline.samples = 4;
  }

  private checkIfLoaded() {
    if (this.bodyMeshes.length > 0 && this.capMeshes.length > 0 && this.valveMeshes.length > 0) {
      this.bodyMeshes.forEach(mesh => {
        mesh.setEnabled(true);
      });
      this.capMeshes.forEach(mesh => {
        mesh.setEnabled(true);
      });
      this.valveMeshes.forEach(mesh => {
        mesh.setEnabled(true);
      });
      this.animate();
      this.readyEvent.next(true);
      setTimeout(() => {
        this.resetCamera();
      }, 100);
    }
  }

  public reset() {
    if (!this.scene) {
      return;
    }

    this.scene.stopAllAnimations();

    this.bodyMeshes.forEach(mesh => {
      mesh.dispose();
    });
    this.bodyMeshes = [];

    this.capMeshes.forEach(mesh => {
      mesh.dispose();
    });
    this.capMeshes = [];

    this.valveMeshes.forEach(mesh => {
      mesh.dispose();
    });
    this.valveMeshes = [];

    this.readyEvent.next(false);
  }

  private resetCamera() {
    const minMax = Mesh.MinMax([...this.bodyMeshes, ...this.capMeshes, ...this.valveMeshes]);
    this.cameraAngle = Math.PI / 8;
    this.cameraRadius = (minMax.max.y / 2) * 3;
    this.cameraPointY = minMax.max.y / 2;
    const dx = Math.cos(this.cameraAngle) * this.cameraRadius;
    const dy = Math.sin(this.cameraAngle) * this.cameraRadius;
    this.camera.position = new Vector3(0, this.cameraPointY + dy, -dx);
    this.camera.setTarget(new Vector3(0, this.cameraPointY, 0));
  }

  public animate(): void {
    // We have to run this outside angular zones,
    // because it could trigger heavy changeDetection cycles.
    this.ngZone.runOutsideAngular(() => {
      const rendererLoopCallback = () => {
        this.scene.render();
      };

      if (window.document.readyState !== 'loading') {
        this.engine.runRenderLoop(rendererLoopCallback);
      } else {
        window.window.addEventListener('DOMContentLoaded', () => {
          this.engine.runRenderLoop(rendererLoopCallback);
        });
      }

      window.window.addEventListener('resize', () => {
        this.engine.resize();
      });
    });

    this.engine.resize();
  }

  public setWaterBottleObj(url: string, capYOffset: number) {
    this.bodyMeshes.forEach(mesh => {
      mesh.dispose();
    });

    this.capYOffset = capYOffset;

    const index = url.lastIndexOf('/');
    let folder = url;
    let filename = '';
    if (index !== -1) {
      folder = url.substring(0, index + 1);
      filename = url.substr(index + 1);
    }
    SceneLoader.ImportMeshAsync(null, folder, filename, this.scene)
      .then(result => {
        result.meshes.forEach(mesh => {
          mesh.translate(new Vector3(0, 1, 0), 0, Space.BONE);
          const tempM = new StandardMaterial('material', this.scene);
          tempM.specularColor = new BABYLON.Color3(0, 0, 0);
          mesh.material = tempM;
          tempM.diffuseColor = new BABYLON.Color3(1, 1, 1);
          mesh.setEnabled(false);
        });
        this.bodyMeshes = result.meshes;
        this.checkIfLoaded();
      });
  }

  public setCapObj(url: string) {
    this.capMeshes.forEach(mesh => {
      mesh.dispose();
    });

    const index = url.lastIndexOf('/');
    let folder = url;
    let filename = '';
    if (index !== -1) {
      folder = url.substring(0, index + 1);
      filename = url.substr(index + 1);
    }
    SceneLoader.ImportMeshAsync(null, folder, filename, this.scene)
      .then(result => {
        result.meshes.forEach(mesh => {
          mesh.translate(new Vector3(0, 1, 0), this.capYOffset, Space.BONE);
          mesh.material = this.capMaterial;
          mesh.setEnabled(false);
        });
        this.capMeshes = result.meshes;
        this.checkIfLoaded();
      });
  }

  public setValveObj(url: string) {
    this.valveMeshes.forEach(mesh => {
      mesh.dispose();
    });

    const index = url.lastIndexOf('/');
    let folder = url;
    let filename = '';
    if (index !== -1) {
      folder = url.substring(0, index + 1);
      filename = url.substr(index + 1);
    }
    SceneLoader.ImportMeshAsync(null, folder, filename, this.scene)
      .then(result => {
        result.meshes.forEach(mesh => {
          mesh.translate(new Vector3(0, 1, 0), this.capYOffset, Space.BONE);
          mesh.material = this.valveMaterial;
          mesh.setEnabled(false);
        });
        this.valveMeshes = result.meshes;
        this.checkIfLoaded();
      });
  }

  public changeBottleColor(hex: string) {
    this.bottleColor = hex;
    this.bodyMeshes.forEach(mesh => {
      const tempM: StandardMaterial = mesh.material as StandardMaterial;
      if (!tempM.diffuseTexture) {
        if (hex.length === 7) {
          // mesh.cullingStrategy = AbstractMesh.CULLINGSTRATEGY_STANDARD;
          tempM.alpha = 1;
          tempM.diffuseColor = BABYLON.Color3.FromHexString(hex);
          tempM.backFaceCulling = true;
          tempM.separateCullingPass = false;
        } else if (hex.length === 9) {
          // mesh.cullingStrategy = AbstractMesh.CULLINGSTRATEGY_OPTIMISTIC_INCLUSION;
          tempM.diffuseColor = BABYLON.Color3.FromHexString(hex.substr(0, 7));
          tempM.alpha = parseInt(hex.substr(7, 2), 16) / 255;
          tempM.backFaceCulling = false;
          tempM.separateCullingPass = true;
        }
      }
    });
  }

  public changeCapColor(hex: string) {
    this.capMaterial.diffuseColor = BABYLON.Color3.FromHexString(hex);
  }

  public changeValveColor(hex: string) {
    this.valveMaterial.diffuseColor = BABYLON.Color3.FromHexString(hex);
  }

  public changeTexture(blob: Blob, index: number) {
    if (!this.bodyMeshes[index]) {
      return;
    }
    const m: StandardMaterial = this.bodyMeshes[index].material as StandardMaterial;
    if (blob === null) {
      m.diffuseTexture = null;
      m.opacityTexture = null;
      this.changeBottleColor(this.bottleColor);
      return;
    }
    const url = URL.createObjectURL(blob);
    const texture = new Texture(url, this.scene);
    texture.uScale = -1;
    if (this.bottleColor.length === 7) {
      // this.bodyMeshes[index].cullingStrategy = AbstractMesh.CULLINGSTRATEGY_STANDARD;
      m.alpha = 1.0;
      m.diffuseColor = new BABYLON.Color3(1, 1, 1);
      m.diffuseTexture = texture;
      m.opacityTexture = null;
    } else {
      // this.bodyMeshes[index].cullingStrategy = AbstractMesh.CULLINGSTRATEGY_OPTIMISTIC_INCLUSION;
      m.alpha = 1.0;
      m.diffuseColor = new BABYLON.Color3(1, 1, 1);
      m.diffuseTexture = texture;
      m.opacityTexture = texture;
    }
  }

  public get readyStatus(): Observable<boolean> {
    return this.readyEvent.asObservable();
  }

  private resetCameraScreenshot() {
    const minMax = Mesh.MinMax([...this.bodyMeshes, ...this.capMeshes, ...this.valveMeshes]);
    this.cameraAngle = 0;
    this.cameraRadius = (minMax.max.y / 2) * 3;
    this.cameraPointY = minMax.max.y / 2;
    const dx = Math.cos(this.cameraAngle) * this.cameraRadius;
    const dy = Math.sin(this.cameraAngle) * this.cameraRadius;
    this.camera.position = new Vector3(0, this.cameraPointY + dy, -dx);
    this.camera.setTarget(new Vector3(0, this.cameraPointY, 0));
    for (const mesh of this.bodyMeshes) {
      mesh.rotation = new Vector3(0, 0, 0);
    }
    for (const mesh of this.capMeshes) {
      mesh.rotation = new Vector3(0, 0, 0);
    }
    for (const mesh of this.valveMeshes) {
      mesh.rotation = new Vector3(0, 0, 0);
    }
  }

  public takeScreenshot(callback: (data: string) => void) {
    this.resetCameraScreenshot();
    MeshBuilder.CreateBox('box', {}, this.scene);
    this.scene.render();
    Tools.CreateScreenshotUsingRenderTarget(this.engine, this.camera, 800, data => {
      // callback(data);
      
      // add watermark
      const canvas = document.createElement('canvas');
      canvas.width = 800;
      canvas.height = 800;
      const ctx = canvas.getContext('2d');

      ctx.beginPath();
      ctx.rect(0, 0, 800, 800);
      ctx.fillStyle = "white";
      ctx.fill();

      var image = new Image();
      image.onload = function() {
        ctx.drawImage(image, 0, 0);

        var watermark = new Image();
        watermark.onload = function() {
          ctx.drawImage(watermark, 0, 0, 2481, 2482, 0, 0, 800, 800);

          canvas.toBlob((blob) => {
            
            const reader = new FileReader();
            reader.readAsDataURL(blob);
            reader.onload = () => {
              const base64 = reader.result as string;
              callback(base64);
            };

          });
        };
        watermark.src = '/assets/img/watermark.png';
      };
      image.src = data;

    }, 'image/png');
  }
}
