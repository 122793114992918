import { NgModule } from '@angular/core';
import { Routes, RouterModule, ExtraOptions } from '@angular/router';
import { LoginComponent } from './pages/login/login.component';
import { HomeComponent } from './pages/home/home.component';
import { BaseComponent } from './pages/base/base.component';
import { RegisterComponent } from './pages/register/register.component';
import { ConfiguratorComponent } from './pages/configurator/configurator.component';
import { LoginConfigurationComponent } from './pages/login-configuration/login-configuration.component';
import { QuotationRequestComponent } from './pages/quotation-request/quotation-request.component';
import { FeedbackComponent } from './pages/feedback/feedback.component';
import { ConfigurationsComponent } from './pages/configurations/configurations.component';
import { LoginGuardService } from './services/login-guard.service';
import { RegisterConfigurationComponent } from './pages/register-configuration/register-configuration.component';
import { PasswordRecoverComponent } from './pages/password-recover/password-recover.component';
import { PasswordSentComponent } from './pages/password-sent/password-sent.component';


const routes: Routes = [
  {
    path: '',
    redirectTo: '/home',
    pathMatch: 'full'
  },
  {
    canActivate: [LoginGuardService],
    path: '',
    component: BaseComponent,
    children: [
      {
        path: 'home',
        component: HomeComponent
      },
      {
        path: 'configurator',
        component: ConfiguratorComponent
      },
      {
        path: 'quotation-request',
        component: QuotationRequestComponent
      },
      {
        path: 'configurations',
        component: ConfigurationsComponent
      },
    ]
  },
  {
    path: 'login',
    component: LoginComponent
  },
  {
    path: 'login-configuration',
    component: LoginConfigurationComponent
  },
  {
    path: 'feedback',
    component: FeedbackComponent
  },
  {
    path: 'register',
    component: RegisterComponent
  },
  {
    path: 'register-configuration',
    component: RegisterConfigurationComponent
  },
  {
    path: 'password-recover',
    component: PasswordRecoverComponent
  },
  {
    path: 'password-sent',
    component: PasswordSentComponent
  }
];

const config: ExtraOptions = {
  useHash: false,
};

@NgModule({
  imports: [RouterModule.forRoot(routes, config)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
