import { Component, OnInit, ViewChild, ElementRef, Renderer2 } from '@angular/core';

@Component({
  selector: 'app-customization-area-image',
  templateUrl: './customization-area-image.component.html',
  styleUrls: ['./customization-area-image.component.scss']
})
export class CustomizationAreaImageComponent implements OnInit {

  @ViewChild('image') element: ElementRef;

  imageSrc = '';

  constructor(private renderer: Renderer2) { }

  ngOnInit(): void {
  }

  public setPosition(x: number, y: number, width: number, height: number) {
    this.renderer.setStyle(this.element.nativeElement, 'top', y + 'px');
    this.renderer.setStyle(this.element.nativeElement, 'left', x + 'px');
    this.renderer.setStyle(this.element.nativeElement, 'width', width + 'px');
    this.renderer.setStyle(this.element.nativeElement, 'height', height + 'px');
  }

  public setImageBase64(src: string) {
    this.renderer.setStyle(this.element.nativeElement, 'background-image', 'url(' + src + ')');
  }

}
