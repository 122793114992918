import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { LangChangeEvent, TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';
import { Configuration } from 'src/app/models/configuration';
import { ConfigurationService } from 'src/app/services/configuration.service';
import { EngineService } from 'src/app/services/engine.service';
import { EventService } from 'src/app/services/event.service';
import { MainService } from 'src/app/services/main.service';
import { QuotationService } from 'src/app/services/quotation.service';
import { Utils } from 'src/app/services/utils';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-configurations',
  templateUrl: './configurations.component.html',
  styleUrls: ['./configurations.component.scss']
})
export class ConfigurationsComponent implements OnInit, OnDestroy {

  // Modal variables
  showModal = false;
  urlToShare = '';
  configurationId = 0;
  showDeleteModal = false;
  elementToDelete: Configuration;

  private langSub: Subscription;

  elements = [];
  colors = [];
  capIds = [];
  constructor(private configurationsService: ConfigurationService,
              private quotationsService: QuotationService,
              private mainService: MainService,
              private router: Router,
              private engServ: EngineService,
              private eventService: EventService,
              private utils: Utils,
              private translate: TranslateService) { }

  ngOnInit(): void {
    this.eventService.showSpinner();
    this.elements = [];
    this.colors = [];
    this.utils.getColors().then(colors => {
      this.colors = colors;
      this.loadConfigurations();
    }).catch(error => {this.eventService.hideSpinner(); });
    this.langSub = this.translate.onLangChange.subscribe((event: LangChangeEvent) => {
      this.updateColorNames();
    });
  }

  ngOnDestroy() {
    this.langSub.unsubscribe();
  }

  loadConfigurations() {
    this.elements = [];
    this.configurationsService.getConfigurations().then(configs => {
      if (configs.items) {
        this.utils.setNumber();
        configs.items.forEach(config => {
          const image = config.images.filter(img => {
            return img.area_index === 0;
          });
          if (image[0] !== undefined) {
            if (image[0].image_url !== undefined) {
              config.image = image[0].image_url;
            }
          }

          if (this.capIds.indexOf(config.cap_id) === -1) {
            this.capIds.push(config.cap_id);
          }
          config.capName = '-';
          config.valveName = '-';
          this.elements.push(config);
        });
        this.updateColorNames();
        this.eventService.hideSpinner();

        this.capIds.forEach(value => {
          this.loadCapData(value);
        });
      } else {
        this.eventService.hideSpinner();
      }
      console.log(this.elements);
    }).catch(error => {
      this.eventService.hideSpinner();
    });
  }

  private updateColorNames() {
    this.elements.forEach(config => {
      const colorCap = this.colors.filter(element => {
        return element.hex === config.cap_color_hex;
      });
      const colorBottle = this.colors.filter(element => {
        return element.hex === config.water_bottle_color_hex;
      });
      const colorValve = this.colors.filter(element => {
        return element.hex === config.valve_color_hex;
      });
      config.water_bottle_color_name = this.utils.mapColorName(colorBottle[0].hex, colorBottle[0].name);
      config.cap_color_name = this.utils.mapColorName(colorCap[0].hex, colorCap[0].name);
      config.valve_color_name = this.utils.mapColorName(colorValve[0].hex, colorValve[0].name);
    });
  }

  private loadCapData(id: number) {
    this.mainService.getCap(id).then(cap => {
      let capName = this.translate.instant('color_cap', {cap: this.utils.getTextForKey(cap.texts, 'cap', 'name')});
      capName = capName.substr(0, 1).toUpperCase() + capName.substring(1).toLowerCase();
      let valveName = this.translate.instant('color_valve', {valve: this.utils.getTextForKey(cap.texts, 'valve', 'name')});
      valveName = valveName.substr(0, 1).toUpperCase() + valveName.substring(1).toLowerCase();

      this.elements.forEach(element => {
        if (element.cap_id === id) {
          element.capName = capName;
          element.valveName = valveName;
        }
      });
    }).catch(error => {
      this.eventService.hideSpinner();
    });
  }

  openShareModal(element: any) {
    console.log(element);
    if (!this.showModal) {
      const fileName = element.image.substr(element.image.lastIndexOf('/') + 1);
      const url = 'https://' + environment.imagesDomain + '/' + element.id + '/' + fileName;
      this.urlToShare = url;
      this.configurationId = element.id.toString();
      this.showModal = true;
    }
  }


  deleteElement(element: Configuration) {
    this.elementToDelete = element;
    this.showDeleteModal = true;
  }

  hideDeleteModal() {
    this.elementToDelete = null;
    this.showDeleteModal = false;
  }

  deleteElementConfirmed() {
    this.showDeleteModal = false;
    this.eventService.showSpinner();

    this.configurationsService.deleteConfiguration(this.elementToDelete.id).then(() => {
       this.utils.setNumber();
       this.loadConfigurations();
       this.elementToDelete = null;
     }).catch(error => {
       this.eventService.hideSpinner();
       this.elementToDelete = null;
     });
  }

  goToRequestQuot(element: any) {
    this.eventService.showSpinner();
    this.quotationsService.setCapID(element.cap_id);
    this.quotationsService.setBottleID(element.water_bottle_id);
    this.quotationsService.setColorCap(element.cap_color_hex);
    this.quotationsService.setColorBottle(element.water_bottle_color_hex);
    this.quotationsService.setColorValve(element.valve_color_hex);
    this.quotationsService.setCapacity(element.water_bottle_capacity);
    this.quotationsService.setQuantity(element.quantity);
    this.quotationsService.setPrice(element.price);
    this.quotationsService.setImages(element.images);
    this.quotationsService.setWaterBottleImage(element.image);
    this.router.navigate(['/quotation-request'], { queryParams: { config: true, id: element.id } });
  }

}
