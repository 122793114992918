import { Component, OnInit } from '@angular/core';
import { UserService } from 'src/app/services/user.service';
import { AuthRequest } from 'src/app/models/authRequest';
import { UserDataService } from 'src/app/services/user-data.service';
import { Router } from '@angular/router';
import { EventService } from 'src/app/services/event.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

  constructor(private router: Router,
              private userService: UserService,
              private userDataService: UserDataService,
              private eventService: EventService) { }

  email?: string;
  password?: string;
  error = false;
  showPassword = false;

  ngOnInit(): void {
  }

  login() {
    if (!this.email || !this.password) {
      return;
    }

    this.eventService.showSpinner();
    const auth: AuthRequest = {
      email: this.email,
      password: this.password
    };
    this.userService.auth(auth).then(token => {
      this.error = false;
      localStorage.setItem('token', token.accessToken);
      this.userDataService.checkUser().then(user => {
        if (user !== null) {
          if (this.userDataService.configuratorStatus) {
            this.router.navigate(['/configurator'], { queryParams: { id: this.userDataService.configuratorStatus.id } });
          } else {
            this.router.navigate(['/home']);
          }
        } else {
          this.eventService.hideSpinner();
        }
      }).catch(error => {
        console.log(error);
        this.eventService.hideSpinner();
        return null;
      });
    }).catch(error => {
      console.log(error);
      this.error = true;
      this.eventService.hideSpinner();
      return null;
    });
  }

  goToRegisterPage() {
    this.router.navigate(['/register']);
  }

  goToPasswordRecoverPage() {
    this.router.navigate(['/password-recover']);
  }

  showHidePassword() {
    this.showPassword = !this.showPassword;
  }
}
