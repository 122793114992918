import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Configuration } from 'src/app/models/configuration';
import { Quotation } from 'src/app/models/quotation';
import { ConfigurationService } from 'src/app/services/configuration.service';
import { EventService } from 'src/app/services/event.service';
import { MainService } from 'src/app/services/main.service';
import { QuotationService } from 'src/app/services/quotation.service';
import { UserDataService } from 'src/app/services/user-data.service';
import { Utils } from 'src/app/services/utils';

@Component({
  selector: 'app-quotation-request',
  templateUrl: './quotation-request.component.html',
  styleUrls: ['./quotation-request.component.scss']
})
export class QuotationRequestComponent implements OnInit {

  constructor(private router: Router, private route: ActivatedRoute,
              private eventService: EventService, private userDataService: UserDataService,
              private quotationService: QuotationService, private utils: Utils,
              private configurationsService: ConfigurationService,
              private mainService: MainService,
              private translate: TranslateService) { }

  clickFirst = false;
  clickSecond = false;
  firstName: string;
  lastName: string;
  email: string;
  colorCap: string;
  colorBottle: string;
  colorCapName: string;
  colorBottleName: string;
  colorValveName: string;
  capacity: number;
  quantity: number;
  images = [];
  filesToUpload = [];
  quotationModel: Quotation;
  configurationModel: Configuration;
  configurationId: number;
  status = false;
  user: any;
  bottleURL: any;
  capName = '';
  valveName = '';

  data: any;

  ngOnInit(): void {
    this.bottleURL = null;
    this.configurationModel = {};
    this.quotationModel = {
      notes: null
    };
    this.userDataService.checkUser().then(user => {
      this.quotationModel.email = user.email;
    });

    this.route.queryParams.subscribe(params => {
      this.status = params.config;
      this.configurationId = params.id;
    });
    this.filesToUpload = [];
    this.configurationModel.images = [];
    this.quotationService.colorCap.subscribe(color => this.configurationModel.cap_color_hex = color);
    this.quotationService.colorBottle.subscribe(color => this.configurationModel.water_bottle_color_hex = color);
    this.quotationService.colorValve.subscribe(color => this.configurationModel.valve_color_hex = color);
    this.quotationService.capacity.subscribe(capacity => this.configurationModel.water_bottle_capacity = capacity);
    this.quotationService.quantity.subscribe(quantity => this.configurationModel.quantity = quantity);
    this.quotationService.price.subscribe(price => this.configurationModel.price = price);
    this.quotationService.capId.subscribe(id => {
      this.configurationModel.cap_id = id;
      this.mainService.getCap(id).then(cap => {
        const capName = this.translate.instant('color_cap', {cap: this.utils.getTextForKey(cap.texts, 'cap', 'name')});
        this.capName = capName.substr(0, 1).toUpperCase() + capName.substring(1).toLowerCase();
        const valveName = this.translate.instant('color_valve', {valve: this.utils.getTextForKey(cap.texts, 'valve', 'name')});
        this.valveName = valveName.substr(0, 1).toUpperCase() + valveName.substring(1).toLowerCase();
      }).catch(error => {
        this.eventService.hideSpinner();
      });
    });
    this.quotationService.bottleId.subscribe(id => this.configurationModel.water_bottle_id = id);
    let array = [];
    let img = null;
    this.quotationService.images.subscribe(images => array = images);
    this.quotationService.waterBottleImage.subscribe(image => img = image);
    console.log(img);

    if ( img !== '') {
      const reader = new FileReader();

      const block = img.split(';');
      if (block[1] === undefined) {
        this.bottleURL = img;
      } else {
      // Get the content type of the image
      const contentType = block[0].split(':')[1]; // In this case "image/png"
  // get the real base64 content of the file
      const realDatabase64 = block[1].split(',')[1];
      const blob = this.b64toBlob(realDatabase64, contentType);
      reader.readAsDataURL(blob);
      reader.onloadend = () => {
          const base64data = reader.result;
          const src = '' + base64data;
          const obj = {
            srcImage: src,
          };
          this.bottleURL = obj.srcImage;
          this.filesToUpload.push(obj);
          console.log(this.filesToUpload);
        };
      }


    }

    this.utils.getColors().then(colors => {
       const colorCap = colors.filter(element => {
        return element.hex === this.configurationModel.cap_color_hex;
      });
       this.colorCapName = colorCap[0].name;
       const colorBottle = colors.filter(element => {
        return element.hex === this.configurationModel.water_bottle_color_hex;
      });
       this.colorBottleName = colorBottle[0].name;
       const colorValve = colors.filter(element => {
        return element.hex === this.configurationModel.valve_color_hex;
      });
       this.colorValveName = colorValve[0].name;
    }).catch(error => {this.eventService.hideSpinner(); });

    this.userDataService.checkUser().then(user => {
      console.log(user);
      this.user = user;
      this.firstName = user.firstName;
      this.lastName = user.lastName;
      this.email = user.email;
      this.configurationModel.user_id = user.id;
      this.eventService.hideSpinner();
    }).catch(error => {
      /* this.firstName = '';
      this.lastName = '';
      this.email = ''; */
      this.eventService.hideSpinner();
    });

    array.forEach(element => {
      this.configurationModel.images.push(element.srcImage);
    });

    this.filesToUpload = array;

  }

  clickCheckbox1() {
    if (this.clickFirst === false) {
      this.clickFirst = true;
    } else {
      this.clickFirst = false;
    }
  }

  clickCheckbox2() {
    if (this.clickSecond === false) {
      this.clickSecond = true;
    } else {
      this.clickSecond = false;
    }
  }

  randomString(length, chars) {
    let result = '';
    for (let i = length; i > 0; --i) { result += chars[Math.floor(Math.random() * chars.length)]; }
    return result;
  }

  sendRequest() {
    this.eventService.showSpinner();
    this.quotationModel.code = this.randomString(12, '0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZ');
/*     this.quotationModel.email = this.email;
    this.quotationModel.name = this.firstName;
    this.quotationModel.surname = this.lastName; */

  /*   if (this.user == null) {
      console.log(this.quotationModel);
      this.quotationModel.configuration_id = null;
      this.quotationService.uploadQuotation(this.quotationModel).then(response => {
        this.quotationModel = response;
        this.saveImagesQuotation();
      }).catch(error => {
      });
    }  */
    if (this.configurationId === undefined) {
      if (this.user === null) {
        this.configurationModel.user_id = 0;
      }
      console.log(this.configurationModel);
      this.configurationsService.uploadConfiguration(this.configurationModel).then(response => {
          this.configurationModel = response;
          this.configurationId =  this.configurationModel.id;
          this.saveImages();
        }).catch(error => {
          this.eventService.hideSpinner();
        });
      } else {
        this.configurationsService.updateConfiguration(this.configurationId).then(response => {
          this.saveQuotation();
        }).catch(error => {
          this.eventService.hideSpinner();
        });
      }
  }

  saveImagesQuotation() {
    if (this.filesToUpload.length === 0) {
      this.quotationService.setCode(this.quotationModel.code);
      if (this.configurationModel.price) {
        this.router.navigate(['/feedback'], { queryParams: { order: 'true' } });
      } else {
        this.router.navigate(['/feedback']);
      }
      return;
    }
    const image = this.filesToUpload[0];
    const block = image.srcImage.split(';');
    let data = {
      area_index: null,
      assetContentType: null,
    };
    if (!image.area_index) {
      data = {
        area_index: 0,
        assetContentType: block[0].split(':')[1],
      };
    } else {
      data = {
        area_index: image.area_index,
        assetContentType: block[0].split(':')[1],
      };
    }

    this.quotationService.uploadQuotationAsset(this.quotationModel.id, data, data.area_index).then((response: any) => {
      this.saveNextImageQuotation(response);
    }).catch(error => {
      this.eventService.hideSpinner();
      console.log(error);
    });
  }

  saveImages() {
    if (this.filesToUpload.length === 0) {
      this.saveQuotation();
      return;
    }
    const image = this.filesToUpload[0];
    const block = image.srcImage.split(';');
    let data = {
      area_index: null,
      assetContentType: null,
    };
    if (!image.area_index) {
      data = {
        area_index: 0,
        assetContentType: block[0].split(':')[1],
      };
    } else {
      data = {
        area_index: image.area_index,
        assetContentType: block[0].split(':')[1],
      };
    }

    this.configurationsService.uploadConfigurationAsset(this.configurationModel.id, data, data.area_index).then((response: any) => {
      this.saveNextImage(response);
    }).catch(error => {
      this.eventService.hideSpinner();
      console.log(error);
    });
  }

  saveNextImageQuotation(response: any) {
    const fileToUpload = this.filesToUpload[0];
    const block = fileToUpload.srcImage.split(';');
// Get the content type of the image
    const contentType = block[0].split(':')[1]; // In this case "image/png"
// get the real base64 content of the file
    const realDatabase64 = block[1].split(',')[1];
    this.utils.uploadFile(response.fileUploadResponse, realDatabase64, contentType).subscribe(() => {
      this.filesToUpload.splice(0, 1);
      this.saveImagesQuotation();
    }, (error: any) => {

    });
  }

  saveNextImage(response: any) {
    const fileToUpload = this.filesToUpload[0];
    const block = fileToUpload.srcImage.split(';');
// Get the content type of the image
    const contentType = block[0].split(':')[1]; // In this case "image/png"
// get the real base64 content of the file
    const realDatabase64 = block[1].split(',')[1];
    this.utils.uploadFile(response.fileUploadResponse, realDatabase64, contentType).subscribe(() => {
      this.filesToUpload.splice(0, 1);
      this.saveImages();
    }, (error: any) => {

    });
  }

  saveQuotation() {
    if (this.quotationModel.name) {
      this.data = {
        configuration_id: this.configurationId,
        messagge: this.quotationModel.messagge,
        notes: this.quotationModel.notes,
        email: this.quotationModel.email,
        name: this.quotationModel.name,
        surname: this.quotationModel.surname,
        residence_state: this.quotationModel.residence_state,
        phone_number: this.quotationModel.phone_number,
        code: this.quotationModel.code,
        lang: this.translate.currentLang
      };
    } else {
      this.data = {
        configuration_id: this.configurationId,
        messagge: this.quotationModel.messagge,
        notes: this.quotationModel.notes,
        email: this.quotationModel.email,
        name: this.firstName,
        surname: this.lastName,
        residence_state: this.quotationModel.residence_state,
        phone_number: this.quotationModel.phone_number,
        code: this.quotationModel.code,
        lang: this.translate.currentLang
      };
    }

    console.log(this.quotationModel);
    this.quotationService.uploadQuotation(this.data).then(response => {
      this.quotationService.setSerial(response.counter);
      this.completeConfiguration();
    }).catch(error => {
      this.eventService.hideSpinner();
      console.log(error);
      return null;
    });
  }

  completeConfiguration() {
    this.configurationsService.updateConfiguration(this.configurationId).then(response => {
      this.quotationService.postQuotationEmail(this.data).then(() => {
        this.quotationService.setCode(this.quotationModel.code);
        if (this.configurationModel.price) {
          this.router.navigate(['/feedback'], { queryParams: { order: 'true' } });
        } else {
          this.router.navigate(['/feedback']);
        }
      }).catch(error => {
        this.eventService.hideSpinner();
        console.log(error);
      });
    }).catch(error => {
      this.eventService.hideSpinner();
      console.log(error);
      return null;
    });
  }

  b64toBlob(b64Data, contentType, sliceSize?) {
    contentType = contentType || '';
    sliceSize = sliceSize || 512;

    const byteCharacters = atob(b64Data);
    const byteArrays = [];

    for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
        const slice = byteCharacters.slice(offset, offset + sliceSize);

        const byteNumbers = new Array(slice.length);
        for (let i = 0; i < slice.length; i++) {
            byteNumbers[i] = slice.charCodeAt(i);
        }

        const byteArray = new Uint8Array(byteNumbers);

        byteArrays.push(byteArray);
    }

    const blob = new Blob(byteArrays, {type: contentType});
    return blob;
  }

  getOrderPrice(): number {
    return Math.round(this.configurationModel.price * this.configurationModel.quantity * 100) / 100;
  }
}
