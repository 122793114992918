<div *ngIf="step === 0">

    <div class="section">
        <div class="container ">
            <p class="box1-title ">{{ 'capacity_bottle' | translate }}</p>
        </div>
        <div class="container register">
            <p class="box1-subtitle ">{{ 'select_capacity_bottle' | translate }}</p>
        </div>

        <div class="container">
            <div class="buttons" *ngIf="variants">
                <button class="button" *ngFor="let variant of variants"
                    [ngClass]="{'selected': variant.id === selectedVariant}"
                    (click)="variantSelected(variant.id)">
                    <span class="capacity">{{ variant.capacity }}ml</span>
                </button>
            </div>
        </div>

        <hr class="line capacity">
    </div>

    <div class="section second">
        <div class="container">
            <p class="box1-title">{{ 'quantity_and_price' | translate }}</p>
        </div>
        <div class="container register quantity">
            <p class="box1-subtitle">{{ 'quantity_text' | translate }}</p>
        </div>
        <div class="container quantity">
            <div style="margin-top: 15px;">
                <div class="select is-danger is-small">
                    <select [(ngModel)]="userQuantity" (change)="quantityChanged()">
                        <option *ngFor="let quantity of quantities" [ngValue]="quantity">{{quantity.quantity}}{{quantity.price ? ' - ' + quantity.price + '€  ' : '' }}<span *ngIf="quantity.price > 0">{{ 'price_vat' | translate }}</span></option>
                    </select>
                </div>
            </div>
        </div>
        <div class="container" *ngIf="userQuantity">
            <p class="box1-subtitle" *ngIf="userQuantity.price > 0">{{ 'price_vat_transport' | translate }}</p>
            <p class="box1-subtitle" *ngIf="userQuantity.price == 0">{{ 'price_offer' | translate }}</p>
        </div>
        <div class="error" *ngIf="minQuantityError">{{ 'quantity_error' | translate }} {{ minQuantity }}</div>

        <hr class="line capacity">
    </div>

    <div class="section second">
        <div class="container ">
            <p class="box1-title ">{{ valveName }}</p>
        </div>
        <div class="container register">
            <p class="box1-subtitle ">{{ 'select_valve_color' | translate }}</p>
        </div>
        <div class="container ">
            <div class="dots">
                <div *ngFor="let color of valveColors; let indexOfelement = index;"
                    class="dot" [ngClass]="{'selected': color.selected}" (click)="onValveColorSelected(indexOfelement)">
                    <div class="dot-inner" [ngStyle]="{'background-color': color.color_hex}">
                        <div class="color-tooltip">
                            <h3>{{ getColorName(color) }}</h3>
                            <i></i>
                        </div>
                    </div>
                </div>
            </div>
            <div class="color-name"></div>
        </div>
        <hr class="line">
    </div>

    <div class="section second">
        <div class="container ">
            <p class="box1-title ">{{ capName }}</p>
        </div>
        <div class="container register">
            <p class="box1-subtitle ">{{ 'select_cap_color' | translate }}</p>
        </div>
        <div class="container">
            <div class="dots">
                <div *ngFor="let color of capColors; let indexOfelement = index;"
                    class="dot" [ngClass]="{'selected': color.selected}" (click)="onCapColorSelected(indexOfelement)">
                    <div class="dot-inner" [ngStyle]="{'background-color': color.color_hex}">
                        <div class="color-tooltip">
                            <h3>{{ getColorName(color) }}</h3>
                            <i></i>
                        </div>
                    </div>
                </div>
            </div>
            <div class="color-name"></div>
        </div>
        <hr class="line">
    </div>

    <div class="section second">
        <div class="container ">
            <p class="box1-title ">{{ 'color_bottle' | translate }}</p>
        </div>
        <div class="container register">
            <p class="box1-subtitle ">{{ 'select_color' | translate }}</p>
        </div>
        <div class="container">
            <div class="dots">
                <div *ngFor="let color of bottleColors; let indexOfelement = index;"
                    class="dot" [ngClass]="{'selected': color.selected}" (click)="onBottleColorSelected(indexOfelement)">
                    <div class="dot-inner" [ngStyle]="{'background-color': color.color_hex}">
                        <div class="color-tooltip">
                            <h3>{{ getColorName(color) }}</h3>
                            <i></i>
                        </div>
                    </div>
                </div>
            </div>
            <div class="color-name"></div>
        </div>
        <hr class="line">
    </div>

    <div class="section second">
        <div class="container ">
            <p class="box1-title ">{{ 'features' | translate }}</p>
        </div>
        <div class="container register quantity" >
            <p class="box1-subtitle " style="width: auto; height: auto;" [innerHTML]="description"></p>
        </div>

        <hr class="line description">
    </div>

</div>

<div *ngIf="step === 1">
    <div>
        <div class="section">
            <div class="container">
                <p class="custom box1-subtitle ">{{ 'area_superior_text' | translate }}</p>
            </div>
        </div>
    </div>

    <div *ngIf="bottle">
        <div *ngFor="let area of bottle.areas; let indexOfelement = index;" class="section area">
            <app-customization-area #customArea (changed)="onAreaChanged($event)"></app-customization-area>

            <hr class="line drop">
        </div>
    </div>

    <div *ngIf="bottle.pdfTemplateUrl" class="section second">
        <div class="last">
            <div class="columns is-mobile">
                <div class="column first">
                    <div style=" width: 32px;height: 32px; background: #FFFFFF;border-radius: 50%;margin-left: 15px; margin-top: 10px; ">
                        <a href="{{bottle.pdfTemplateUrl}}" target="_blank">
                        <img style="padding-top: 9px;padding-bottom: 10px; padding-left: 10px;padding-right: 9px;"
                        src="/assets/img/icon__download_assets.png"></a>
                    </div>
                </div>
                <div class="column">
                    <div class="last-section ">
                        <p class="last-title ">{{ 'design_question' | translate }}</p>
                    </div>

                    <div class="last-subsection ">
                        <p class="last-subtitle ">{{ 'design_text' | translate }}</p>
                    </div>
                </div>
            </div>
        </div>
    </div>

</div>


<div *ngIf="step === 2">
    <div class="section">
        <div class="container ">
            <p class="summary-title">{{ 'features' | translate }}</p>
        </div>

        <div class="container box">
            <p class="boxSummary">
                <span>{{ 'color_bottle' | translate }}</span>
                <span class="color">
                <span class="color-dot" style="border: 1px solid lightgray;" [ngStyle]="{'background-color': bottleColor.color_hex}"></span>
                    <span class="color-text">{{ getColorName(bottleColor) }}</span>
                </span>
            </p>
        </div>

        <div class="container box">
            <p class="boxSummary">
                <span>{{ capName }}</span>
                <span class="color">
                    <span class="color-dot" style="border: 1px solid lightgray;" [ngStyle]="{'background-color': capColor.color_hex}"></span>
                    <span class="color-text">{{ getColorName(capColor) }}</span>
                </span>
            </p>
        </div>

        <div class="container box">
            <p class="boxSummary">
                <span>{{ valveName }}</span>
                <span class="color">
                    <span class="color-dot" style="border: 1px solid lightgray;" [ngStyle]="{'background-color': valveColor.color_hex}"></span>
                    <span class="color-text">{{ getColorName(valveColor) }}</span>
                </span>
            </p>
        </div>

        <div class="container box">
            <p class="boxSummary">
                <span>{{ 'capacity_bottle' | translate }}</span>
                <span class="color">
                    <span class="color-text" *ngIf="bottle">{{ bottle.capacity }}ml</span>
                </span>
            </p>
        </div>

        <div class="container box">
            <p class="boxSummary">
                <span>{{ 'number_order' | translate }}</span>
                <span class="color">
                    <span class="color-text">{{ userQuantity.quantity }}pz</span>
                </span>
            </p>
        </div>

        <div *ngIf="userQuantity.price" class="container box">
            <p class="boxSummary">
                <span>{{ 'order_price' | translate }}</span>
                <span class="color">
                    <span class="color-text">{{ getOrderPrice() }}€</span>
                </span>
            </p>
        </div>

    </div>

    <div *ngIf="bottle">
        <div *ngFor="let src of images; let indexOfelement = index;" class="section" style="padding-top: 25px;">
            <div class="container" *ngIf="images.length === 1">
                <p class="summary-title">{{ ('area' | translate ).toUpperCase()}}</p>
            </div>
            <div class="container" *ngIf="indexOfelement === 0 && src.area_index != 0 && images.length > 1">
                <p class="summary-title">{{ ('area_superior' | translate ).toUpperCase()}}</p>
            </div>
            <div class="container" *ngIf="indexOfelement !== 0 && src.area_index != 0 && images.length > 1">
                <p class="summary-title">{{ ('area_inferior' | translate ).toUpperCase()}}</p>
            </div>

            <div class="container box image" *ngIf="src.area_index != 0">
                <p class="boxSummary">
                    <span>{{ 'image' | translate }}</span>
                    <span class="image-preview" style="background-size: contain;"
                        [ngStyle]="{'background-image': 'url(' + src.srcImage + ')', 'height': src.height + 'px'}">
                    </span>
                </p>
            </div>
        </div>
    </div>

    <!-- <div class="section" style="padding-top: 25px;">
        <div class="container ">
            <p class="summary-title">{{ ('area_superior' | translate ).toUpperCase()}}</p>
        </div>
        <div class="container box">
            <p class="boxSummary">
                <span>{{ 'text' | translate }}</span>
                <span class="color">
                    <span class="color-text">Dragon Team 2020</span>
                </span>
            </p>
        </div>

        <div class="container box image">
            <p class="boxSummary">
                <span>{{ 'image' | translate }}</span>
                <span class="color">
                    <img class="color-image" src="/assets/img/drago.png">
                </span>
            </p>
        </div>
    </div>

    <div class="section" style="padding-top: 25px; margin-bottom: 54px;">
        <div class="container ">
            <p class="summary-title">{{ ('area_inferior' | translate ).toUpperCase()}}</p>
        </div>
        <div class="container box">
            <p class="boxSummary">
                <span>{{ 'text' | translate }}</span>
                <span class="color">
                    <span class="color-text">-</span>
                </span>
            </p>
        </div>

        <div class="container box image">
            <p class="boxSummary">
                <span>{{ 'image' | translate }}</span>
                <span class="color">
                    <img class="color-image" src="/assets/img/drago.png">
                </span>
            </p>
        </div>
    </div> -->


</div>





