// tslint:disable-next-line: max-line-length
import { AfterViewInit, ApplicationRef, Component, ElementRef, HostListener, NgZone, OnDestroy, OnInit, Renderer2, ViewChild } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { LangChangeEvent, TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';
import { EngineComponent } from 'src/app/components/engine.component';
import { Cap } from 'src/app/models/cap';
import { Quantities } from 'src/app/models/quantities';
import { User } from 'src/app/models/user';
import { WaterBottle } from 'src/app/models/waterBottle';
import { ConfigurationService } from 'src/app/services/configuration.service';
import { EngineService } from 'src/app/services/engine.service';
import { EventService } from 'src/app/services/event.service';
import { MainService } from 'src/app/services/main.service';
import { QuotationService } from 'src/app/services/quotation.service';
import { UserDataService } from 'src/app/services/user-data.service';
import { Utils } from 'src/app/services/utils';
import { QuotationRequestComponent } from '../quotation-request/quotation-request.component';
import { AreaTexture, ConfiguratorPageComponent } from './configurator-page/configurator-page.component';

@Component({
  selector: 'app-configurator',
  templateUrl: './configurator.component.html',
  styleUrls: ['./configurator.component.scss']
})
export class ConfiguratorComponent implements OnInit, OnDestroy, AfterViewInit {

  constructor(
    private sanitizer: DomSanitizer,
    private router: Router,
    private route: ActivatedRoute,
    private mainService: MainService,
    private utils: Utils,
    private userDataService: UserDataService,
    private engServ: EngineService,
    private configurationsService: ConfigurationService,
    private eventService: EventService,
    private translate: TranslateService,
    private quotationService: QuotationService,
    private appRef: ApplicationRef, private ngZone: NgZone,
    private renderer: Renderer2) { }
  private queryParamsSub: Subscription;
  private engineReadySub: Subscription;
  private configurationId: number;

  id = 0;
  step = 0;
  email: string;
  userId: number;
  waterBottle: WaterBottle;
  variants: Array<WaterBottle> = [];
  selectedVariant: number;
  quantity: Quantities;
  images = [];
  imagesToUpload = [];
  cap: Cap;
  name: string;
  description: SafeHtml;
  plainDescription = '';
  colorCap: string;
  colorBottle: string;
  colorValve: string;
  engineReady = false;
  dataReady = false;
  user: any;
  waterBottleImage: any;
  @ViewChild('appEngine') appEngine: EngineComponent;
  @ViewChild('page') page: ConfiguratorPageComponent;
  @ViewChild('quotation') quotation: QuotationRequestComponent;
  @ViewChild('engineContainer') engineContainer: ElementRef;

  // tslint:disable-next-line: variable-name
  configs_number: number;

  // Modal
  showModal = false;
  urlToShare = '';
  configurationToShare = 0;

  private langSub: Subscription;
  private saveSub: Subscription;

  ngOnInit(): void {
    this.dataReady = false;
    this.engineReady = false;
    this.engServ.reset();
    this.eventService.showSpinner();
    this.engineReadySub = this.engServ.readyStatus.subscribe(state => {

      if (state) {
        this.eventService.hideSpinner();
      } else {
        this.eventService.showSpinner();
      }

      if (state) {
        this.engineReady = true;
        this.checkSavedStatus();
      }
    });
    this.userDataService.checkUser().then(user => {
      this.user = user;
      if (user) {
        this.email = user.email;
        this.userId = user.id;
      }
    }).catch(error => {
      this.eventService.hideSpinner();
      console.log(error);
      return null;
    });
    this.queryParamsSub = this.route.queryParamMap.subscribe(params => {
      this.engServ.reset();
      this.loadWaterBottle(parseInt(params.get('id'), 10));
    });
    this.langSub = this.translate.onLangChange.subscribe((event: LangChangeEvent) => {
      this.updateDescription();
    });
    this.saveSub = this.eventService.getSave.subscribe(() => {
      this.saveEvent();
    });
  }

  ngOnDestroy() {
    this.queryParamsSub.unsubscribe();
    this.engineReadySub.unsubscribe();
    this.langSub.unsubscribe();
    this.saveSub.unsubscribe();
  }

  ngAfterViewInit() {
    this.resizeEvent();
  }

  @HostListener('window:resize', ['$event'])
  resizeEvent() {
    const height = window.innerHeight;
    this.renderer.setStyle(this.engineContainer.nativeElement, 'height', (height - 280) + 'px');
  }

  private loadWaterBottle(id: number) {
    this.mainService.getWaterBottle(id).then(response => {
      this.id = id;
      this.waterBottle = response;
      this.name = this.utils.getTextForKey(this.waterBottle.texts, 'water_bottle', 'name');
      this.updateDescription();
      this.loadCap(this.waterBottle.caps[0].id);
      if (this.waterBottle.variant) {
        this.loadVariants(this.waterBottle.variant);
      }
    }).catch(error => {
      this.eventService.hideSpinner();
    });
  }

  private updateDescription() {
    if (!this.waterBottle) {
      return;
    }
    this.plainDescription = this.utils.getTextForKey(this.waterBottle.texts, 'water_bottle', 'description');
    this.description = this.sanitizer.bypassSecurityTrustHtml(this.plainDescription);
  }

  private loadCap(id: number) {
    this.mainService.getCap(id).then(response => {
      this.cap = response;
      this.updateEngine();
    }).catch(error => {
      this.eventService.hideSpinner();
    });
  }

  private loadVariants(variant: string) {
    this.mainService.getWaterBottles(variant).then(response => {
      let variants = response.items;
      variants = variants.sort((a, b) => {
        return a.capacity - b.capacity;
      });
      this.variants = variants;
      this.selectedVariant = -1;
      variants.forEach(value => {
        if (value.id === this.id) {
          this.selectedVariant = value.id;
        }
      });
    }).catch(error => {
      this.eventService.hideSpinner();
    });
  }

  variantChanged(id: number) {
    this.page.saveStatus();
    // tslint:disable-next-line: object-literal-shorthand
    this.router.navigate(['/configurator'], { queryParams: { id: id } });
  }

  private updateEngine() {
    this.appEngine.setWaterBottleObj(this.waterBottle.object_url, this.waterBottle.cap_y_offset);
    this.appEngine.setCapObj(this.waterBottle.caps[0].cap_object_url);
    this.appEngine.setValveObj(this.waterBottle.caps[0].valve_object_url);
    this.dataReady = true;
    this.checkSavedStatus();
  }

  private checkSavedStatus() {
    if (this.dataReady && this.engineReady && this.userDataService.configuratorStatus) {
      const status = this.userDataService.configuratorStatus;
      this.page.loadStatus();
      this.userDataService.configuratorStatus = null;
      this.engServ.changeBottleColor(this.page.bottleColor.color_hex);
      this.page.textures = status.textures;
    }
  }

  goToRequestQuot() {
    this.router.navigate(['/quotation-request']);
  }

  save() {
    if (this.step === 0 && this.page.checkStep()) {
      this.page.saveStatus();
      this.step = 1;
    } else if (this.step === 1) {
      if (!this.page.checkAreas()) {
        this.eventService.setTextPopupText(this.translate.instant('error_areas_empty'));
        this.eventService.showTextPopup();
        return;
      }
      this.eventService.showSpinner();
      this.page.save();
      this.page.generateImages(() => {
        this.eventService.hideSpinner();
        this.page.saveStatus();
        this.step = 2;
        this.appRef.tick();
      });
    } else {
      this.userDataService.checkUser().then(user => {

        this.engServ.takeScreenshot(data => {
          this.waterBottleImage = data;
          this.quotationService.setCapID(this.cap.id);
          this.quotationService.setBottleID(this.waterBottle.id);
          this.quotationService.setColorCap(this.colorCap);
          this.quotationService.setColorBottle(this.colorBottle);
          this.quotationService.setColorValve(this.colorValve);
          this.quotationService.setCapacity(this.waterBottle.capacity);
          this.quotationService.setQuantity(this.quantity.quantity);
          this.quotationService.setPrice(this.quantity.price);
          this.quotationService.setImages(this.images);
          this.quotationService.setWaterBottleImage(data);
          this.eventService.showSpinner();
          this.page.saveStatus();
         /*  this.goToRequestQuot(); */

          this.ngZone.run(() => {
            if (user) {
              this.page.saveStatus();
              this.goToRequestQuot();
            } else {
              this.router.navigate(['/login-configuration']);
            }
          });
        });
      }).catch(error => {
        this.eventService.hideSpinner();
        console.log(error);
        return null;
      });
    }
  }

  private saveEvent() {
    if (this.step === 0 && this.page.checkStep()) {
      this.page.saveStatus();
      this.eventService.saveComplete();
    } else if (this.step === 1) {
      this.eventService.showSpinner();
      this.page.save();
      this.page.generateImages(() => {
        this.eventService.hideSpinner();
        this.page.saveStatus();
        this.appRef.tick();
        this.eventService.saveComplete();
      });
    } else {
      this.eventService.saveComplete();
    }
  }

  back() {
    this.page.save();
    this.step--;
  }

  onBottleColorChanged(hex: string) {
    this.engServ.changeBottleColor(hex);
    this.colorBottle = hex;
  }

  onCapColorChanged(hex: string) {
    this.engServ.changeCapColor(hex);
    this.colorCap = hex;
  }

  onValveColorChanged(hex: string) {
    this.engServ.changeValveColor(hex);
    this.colorValve = hex;
  }

  onTextureChanged(area: AreaTexture) {
    this.engServ.changeTexture(area.blob, area.index);
  }

  onQuantityChanged(quant: any) {
    this.quantity = quant;
  }

  onImagesChanged(img: any) {
    this.images = img;
  }

  saveConfiguration() {
    this.eventService.showSpinner();
    this.userDataService.checkUser().then(user => {
      this.quotationService.setCapID(this.cap.id);
      this.quotationService.setBottleID(this.waterBottle.id);
      this.quotationService.setColorCap(this.colorCap);
      this.quotationService.setColorBottle(this.colorBottle);
      this.quotationService.setColorValve(this.colorValve);
      this.quotationService.setCapacity(this.waterBottle.capacity);
      this.quotationService.setQuantity(this.quantity.quantity);
      this.quotationService.setPrice(this.quantity.price);
      this.quotationService.setImages(this.images);
      this.engServ.takeScreenshot(data => {
        this.waterBottleImage = data;
        this.ngZone.run(() => this.afterTakeScreenshot(user));
      });
    }).catch(error => {
      this.eventService.hideSpinner();
      console.log(error);
    });
  }

  private afterTakeScreenshot(user: User) {
    const image = {
      srcImage:  this.waterBottleImage,
      area_index: 0
    };
    this.images.push(image);

    if (user) {
      const data = {
        water_bottle_color_hex: this.colorBottle,
        cap_color_hex: this.colorCap,
        water_bottle_capacity: this.waterBottle.capacity,
        valve_color_hex: this.colorValve,
        water_bottle_id: this.waterBottle.id,
        cap_id: this.cap.id,
        quantity: this.quantity.quantity,
        price: this.quantity.price,
        user_id: this.userId
      };
      console.log(this.images);
      this.configurationsService.uploadConfiguration(data).then(response => {
        this.configurationId = response.id;
        this.imagesToUpload = JSON.parse(JSON.stringify(this.images));
        this.saveAsset();
      }).catch(error => {
        this.eventService.hideSpinner();
        console.log(error);
        return null;
      });
    } else {
      this.router.navigate(['/login-configuration']);
    }
  }

  saveAsset(imageToShare: string = null) {
    if (this.imagesToUpload.length === 0) {
      this.eventService.showSpinner();
      this.appRef.tick();
      console.log('va alle configurazioni');
      this.utils.setNumber();
      this.eventService.hideSpinner();
      this.urlToShare = imageToShare;
      this.configurationToShare = this.getConfigurationId();
      this.showModal = true;
      this.configurationsService.sendConfigurationEmail(this.configurationId).then(response => {
        // mail sent
      }).catch(error => {
        // mail not sent
      });
      return;
    }
    const image = this.imagesToUpload[0];
    console.log(image);
    const block = image.srcImage.split(';');
    let data = {
        area_index: null,
        assetContentType: null,
      };
    data = {
        area_index: image.area_index,
        assetContentType: block[0].split(':')[1],
      };

    this.configurationsService.uploadConfigurationAsset(this.configurationId, data, data.area_index).then((response: any) => {
        this.saveImageS3(response);
      }).catch(error => {
        this.eventService.hideSpinner();
        console.log(error);
      });
    }

    saveImageS3(response: any) {
      const fileToUpload = this.imagesToUpload[0];
      const block = fileToUpload.srcImage.split(';');
      // Get the content type of the image
      const contentType = block[0].split(':')[1]; // In this case "image/png"
      // get the real base64 content of the file
      const realDatabase64 = block[1].split(',')[1];
      this.utils.uploadFile(response.fileUploadResponse, realDatabase64, contentType).subscribe(() => {
        this.imagesToUpload.splice(0, 1);
        this.saveAsset(response.image_url);
      }, (error: any) => {

      });
    }

    moreClicked(event: MouseEvent) {
      event.stopImmediatePropagation();
      this.eventService.setTextPopupText(this.plainDescription);
      this.eventService.showTextPopup();
    }

    onCloseDialog() {
      this.ngZone.run(() => this.router.navigate(['/configurations']));
      return;
    }

    getConfigurationId(): number {
      return this.configurationId;
    }

  }
