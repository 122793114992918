<div class="top-bar-main" id="top-bar-main">
  <div class="user-box dropdown is-right" (click)="profileClicked()" [ngClass]="{'is-active': isMenuActive}" #menu>
    <div class="user-box-content dropdown-trigger">
      <img class="user-img" [src]="imgUrl" *ngIf="userId !== 0">
      <img class="user-img" src="/assets/img/user_placeholder.png" *ngIf="userId === 0">
      <div class="user-data">
        {{ firstName }} {{ lastName }}<br><span class="user-id" *ngIf="userId !== 0">ID {{ userId }}</span>
      </div>
    </div>
    <div class="dropdown-menu" id="dropdown-menu" role="menu">
      <div class="dropdown-content">
        <div class="dropdown-content dropdown-user" *ngIf="userId !== 0">
          {{ firstName }} {{ lastName }}<br><span class="user-id">ID {{ userId }}</span>
        </div>
        <a class="dropdown-item" (click)="loginClicked()" *ngIf="userId === 0">
          {{'menu_login' | translate}}
        </a>
        <a class="dropdown-item" (click)="registerClicked()" *ngIf="userId === 0">
          {{'menu_register' | translate}}
        </a>
        <a class="dropdown-item" (click)="logoutClicked()" *ngIf="userId !== 0">
          {{'menu_logout' | translate}}
        </a>
        <hr class="dropdown-divider">
        <a class="dropdown-item" (click)="changeLanguage('en')">
          English
        </a>
        <a class="dropdown-item" (click)="changeLanguage('it')">
          Italiano
        </a>
      </div>
    </div>
  </div> 

  <div *ngIf="firstName" class="configurations-box" (click)="goToConfigurations()" style="cursor: pointer;">
    <span class="config-count">{{configs_number}}</span>
    <span class="config" *ngIf="configs_number !== 1">{{'configuration_text' | translate}}</span>
    <span class="config" *ngIf="configs_number === 1">{{'configuration_text_singular' | translate}}</span>
    <img src="/assets/img/icon__arrow_right_in_circle.png">
  </div>

  <div class="logo-box">
    <a href="/"><img src="/assets/img/bar_white_logo.png"></a>
  </div>

  <div class="logo-box-mobile">
    <a href="/"><img src="/assets/img/bar_white_logo_mobile.png"></a>
  </div>
</div>
