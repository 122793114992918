import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { RecoverRequest } from 'src/app/models/recoverRequest';
import { EventService } from 'src/app/services/event.service';
import { UserService } from 'src/app/services/user.service';

@Component({
  selector: 'app-password-recover',
  templateUrl: './password-recover.component.html',
  styleUrls: ['./password-recover.component.scss']
})
export class PasswordRecoverComponent implements OnInit {

  email?: string;
  error = false;

  private queryParamsSub: Subscription;
  private src: string;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private eventService: EventService,
    private userService: UserService
  ) {}

  ngOnInit(): void {
    this.queryParamsSub = this.route.queryParamMap.subscribe(params => {
      this.src = params.get('src');
    });
  }

  ngOnDestroy() {
    this.queryParamsSub.unsubscribe();
  }

  recover() {
    if (!this.email) {
      return;
    }

    this.eventService.showSpinner();
    const request: RecoverRequest = {
      email: this.email
    };
    this.userService.recover(request).then(response => {
      this.error = false;
      this.eventService.hideSpinner();
      if (this.src) {
        this.router.navigate(['/password-sent'], {queryParams: {src: this.src}});
      } else {
        this.router.navigate(['/password-sent']);
      }
    }).catch(error => {
      console.log(error);
      this.error = true;
      this.eventService.hideSpinner();
      return null;
    });
  }

}
