import { Component, OnInit, OnDestroy, ViewChild, HostListener, ElementRef, Renderer2 } from '@angular/core';
import { MainService } from 'src/app/services/main.service';
import { WaterBottle } from 'src/app/models/waterBottle';
import { EventService } from 'src/app/services/event.service';
import { from } from 'rxjs';
import { groupBy, mergeMap, toArray } from 'rxjs/operators';
import { UserDataService } from 'src/app/services/user-data.service';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit, OnDestroy {
  public waterBottles?: Array<WaterBottle>;
  searchText: string;
  bottleSearch = [];
  variants = [];
  @ViewChild('searchBar') searchBar: ElementRef;
  @ViewChild('section') section: any;

  constructor(
    private renderer: Renderer2,
    private eventService: EventService,
    private userDataService: UserDataService,
    private mainService: MainService) { }

  ngOnInit(): void {
    this.userDataService.configuratorStatus = null;
    this.eventService.showSpinner();
    this.loadWaterBottles();
  }

  ngOnDestroy() {
  }

  private loadWaterBottles() {
    this.mainService.getWaterBottles().then(response => {
      this.waterBottles = response.items;
      this.refreshVariants();
      this.eventService.hideSpinner();
    }).catch(error => {
      this.eventService.hideSpinner();
    });
  }

  private refreshVariants() {
    const source = from(this.waterBottles);
    const example = source.pipe(groupBy(bottle => bottle.variant),
      mergeMap(group => group.pipe(toArray()))
    );
    this.variants = [];
    example.subscribe(val => this.variants.push(val));
  }

  @HostListener('window:scroll', ['$event']) // for window scroll events
  onScroll(event) {
    const verticalOffset = window.pageYOffset
          || document.documentElement.scrollTop
          || document.body.scrollTop || 0;
    let value = 383 - verticalOffset;
    if (value < 70) {
      value = 70;
    }
    this.renderer.setStyle(this.searchBar.nativeElement, 'top', value + 'px');
  }

  search() {
    this.bottleSearch = [];
    this.waterBottles = [];
    this.eventService.showSpinner();
    this.mainService.getWaterBottles().then(response => {
      this.waterBottles = response.items;
      this.waterBottles.forEach(bottle => {
        if (bottle.name.toLowerCase().includes(this.searchText)) {
          this.bottleSearch.push(bottle);
        } else if (bottle.variant.toLowerCase().includes(this.searchText)) {
          this.bottleSearch.push(bottle);
        } else {
          return;
        }
      });
      this.waterBottles = this.bottleSearch;
      this.refreshVariants()
      this.eventService.hideSpinner();
    }).catch(error => {
      this.eventService.hideSpinner();
    });
  }

}
