import { Injectable } from '@angular/core';
import { Text } from '../models/text';
import { TranslateService } from '@ngx-translate/core';
import { Color, colorNameMapping } from '../models/color';
import { environment } from 'src/environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, BehaviorSubject } from 'rxjs';
import { ConfigurationService } from './configuration.service';
import { ThresholdColor } from '../models/thresholdColor';

export interface ResponseGet {
  items?: Array<any>;
  totalPages?: number;
}

export interface FileUploadResponse {
  url?: string;
  fields?: FileUploadFields;
}

export interface FileUploadFields {
  acl?: string;
  key?: string;
  awsAccessKeyId?: string;
  xAmzSecurityToken?: string;
  policy: string;
  signature: string;
}

@Injectable()
export class Utils {
  private numberConfig = new BehaviorSubject<boolean>(false);
  configurations = this.numberConfig.asObservable();
  constructor(private http: HttpClient, private configsService: ConfigurationService,
              public translate: TranslateService) { }


  setNumber() {
    this.numberConfig.next(true);
    /* this.configsService.getConfigurations().then(configs => {
      this.numberConfig.next(configs.items.length);
    }).catch(error => {
    }); */

  }

  public get refreshNumber(): Observable<boolean> {
    return this.numberConfig.asObservable();
}
  public getTextForKey(texts: Array<Text>, type: string, subType: string): string {
    const lang = this.translate.currentLang;
    let filtered = texts.filter(text => {
      return text.lang === lang && text.type === type && text.sub_type === subType;
    });
    if (filtered.length > 0) {
      return filtered[0].text;
    }
    filtered = texts.filter(text => {
      return text.lang === 'en' && text.sub_type === subType;
    });
    if (filtered.length > 0) {
      return filtered[0].text;
    }
    return '';
  }

  getColors(): Promise<Array<Color>> {
    return this.http.get<Color[]>(environment.apiName + '/colors', { headers: this.getRequestHeaders() }).toPromise();
  }

  uploadFile(fileUploadResponse: FileUploadResponse, file: any, type?: string): Observable<void> {
    const blob = this.b64toBlob(file, type);
    const formData: FormData = new FormData();
    formData.append('key', fileUploadResponse.fields.key);
    formData.append('acl', fileUploadResponse.fields.acl);
    formData.append('AWSAccessKeyId', fileUploadResponse.fields.awsAccessKeyId);
    formData.append('x-amz-security-token', fileUploadResponse.fields.xAmzSecurityToken);
    formData.append('Policy', fileUploadResponse.fields.policy);
    formData.append('Signature', fileUploadResponse.fields.signature);
    formData.append('Content-Type', type);
    formData.append('file', blob);
    /* if (type) {
      formData.append('Content-Type', file.type);
    } else {
      formData.append('Content-Type', 'image/jpeg');
    } */
    return this.http.post<void>(
      fileUploadResponse.url, formData, {});
  }

  b64toBlob(b64Data, contentType, sliceSize?) {
    contentType = contentType || '';
    sliceSize = sliceSize || 512;

    const byteCharacters = atob(b64Data);
    const byteArrays = [];

    for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
        const slice = byteCharacters.slice(offset, offset + sliceSize);

        const byteNumbers = new Array(slice.length);
        for (let i = 0; i < slice.length; i++) {
            byteNumbers[i] = slice.charCodeAt(i);
        }

        const byteArray = new Uint8Array(byteNumbers);

        byteArrays.push(byteArray);
    }

    const blob = new Blob(byteArrays, {type: contentType});
    return blob;
}

  getRequestHeaders() {
    console.log('token', localStorage.getItem('token'));
    return new HttpHeaders(
        {
          'Content-Type': 'application/json',
          Accept: 'application/json'
        }
    );
  }

  mapColorName(hex: string, name: string): string {
    const lang = this.translate.currentLang;
    const mapping = colorNameMapping[hex]
    if (mapping && mapping[lang]) {
      return mapping[lang];
    }
    return name;
  }

}
